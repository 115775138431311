// not social context ///////////////////////////////////

.demographic  {
    width: calc( 100% - 20px );
    overflow: hidden;
    margin: 10px;
}

.demographic-bar-chart {

    position: relative;
    //margin: 20px 10px 10px;
    background: #FFFFFF;
    //width: calc( 100% - 20px );
    width:100%;
    max-width: 100%;
    min-height: 250px;
    overflow: hidden;
    padding-bottom: 32px;

    &.loading {
        background-color: #e9f3fb;
    }

}

.demographic-bar-chart-header {
    font-size: 26px;
    text-align: center;
    margin-top: 16px;
    height: 76px;
    letter-spacing: 0.5px;
}

.demographic-bar-chart-area {
    position:relative;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin: 16px;
    grid-gap: 16px;
}

@mixin chart-reserve( $social, $y-axes-size ) {

    .demographic-bar-chart-area-chart-header-column:nth-last-child(1) {
        width: calc( #{map-get($y-axes-size, "large")}px - 8px );
    }

    .demographic-bar-chart-area-chart-reserve {
        position: relative;
        width: calc( 100% - #{map-get($y-axes-size, "large")}px );
        padding-bottom: 35%;
    }

    $height: 100%;

    @if ($social ) {
        $height: calc( 100% - 25px );
    }

    .demographic-bar-chart-area-chart-reserve-info {
        position: absolute;
        right: -#{map-get($y-axes-size, "large")}px;
        width: #{map-get($y-axes-size, "large")}px;
        height: $height;
        padding-top: 5px;
        padding-right: 8px;
        padding-bottom: 5px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    @media only screen and ( max-width: 1000px ) {

        .demographic-bar-chart-area-chart-reserve {
            width: calc(100% - #{map-get($y-axes-size, "small")}px);
        }

        .demographic-bar-chart-area-chart-header-column:nth-last-child(1) {
            width: calc( #{map-get($y-axes-size, "small")}px - 8px );
        }

        .demographic-bar-chart-area-chart-reserve-info {
            position: absolute;
            right: -#{map-get($y-axes-size, "small")}px;
            width: #{map-get($y-axes-size, "small")}px;
        }
    }

}

.demographic-bar-chart-area-chart-reserve-info-title {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-self: center;
    transform: translateX(10%);
    font-size:14px;
    line-height:1;
    width:100%;
}

.demographic-bar-chart-area-chart-reserve-content {
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
}

.demographic-bar-chart-area-chart-header {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    height: 20px;

    .demographic-bar-chart-area-chart-header-column {
        margin-left: 50px;
    }
}

.demographic-bar-chart-area-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items:center;
}

.demographic-bar-chart-area-info-content {

    width: 50%;
    display: grid;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 8px;
    grid-template: 1fr / 1.2fr 1fr;
    grid-gap: 16px;


    &-icon {

        position:relative;
        color: transparent;
        display: flex;
        align-items: center;
        justify-self: right;
        width: 60%;
        padding-bottom: 60%;
        font-size: 40px;
        border-radius: 100%;
        background-color: #0d2832;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            width: auto;
            height: 45%;
            transform: translate3d(-50%,-50%,0);
        }

        svg path {
            fill: transparent;
            stroke: white;
            stroke-width: 20px;
        }

    }

    &-icon-after {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        transform: translateX(-50px);
        font-size: 8px;
        font-weight: 300;
        background-color: black;
        color: white;
        border-radius: 10px;
        padding: 8px;
        transition: opacity 100ms ease-in;
        box-shadow: 2px 2px 15px rgba(0,0,0,0.2);
        transition-property: opacity, transform;
        border: 1px solid white;
        z-index:1;
        text-transform: capitalize;
    }

    &-div {

        display: flex;
        flex-flow: column;
        text-align: left;

    }

    &-div-wrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-self: baseline;
    }

    &-div-percent {
        font-size: 36px;
        font-weight: 400;
        line-height:1;

        sup {

            font-size: 12px;
            vertical-align: sub;
            display:inline-block;
            margin-left:4px;
        }
    }

    &-div-title {
        font-size: 10px;
        text-transform: capitalize;
    }

    &:hover {

        .demographic-bar-chart-area-info-content-icon-after {
            opacity:1;
            transform:translateX(50%);
        }

    }

}

.half .demographic-bar-chart-area {
    grid-template-columns: 1fr;

    &-info-content-div-percent {
        font-size:24px;
    }

}

@media only screen and ( max-width: 1200px ) {

    .demographic-bar-chart-area {
        grid-template-columns: 1fr;
    }

}

@media only screen and ( max-width: 1000px ) {

    .demographic-container {
        flex-direction: column;
    }
    .half {
        width: 100%;
    }

    .demographic-bar-chart-area-chart-header {
        font-size: 8px;
        padding-left: 16px;
    }
    .demographic-bar-chart-area-chart-reserve-info-title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
    }

    .half .demographic-bar-chart-area {

        grid-template-columns: 1fr;

        &-info-content-div-percent {
            font-size:16px;
        }

    }
}
@media only screen and ( max-width: 800px ) {

    .demographic-bar-chart-area-chart-header {
        font-size: 8px;
        padding-left: 16px;
    }

    .demographic-bar-chart-area-chart-reserve-info-title {
        font-size: 8px;
    }
}

@media only screen and ( max-width: 700px ) {

    .demographic-bar-chart-area-info-content {
        justify-content: space-evenly;
        width: 100%;
        grid-template: 1fr / repeat(2,1fr);

        &-icon {
            width: 40%;
            padding-bottom:40%;
        }

    }

    .half .demographic-bar-chart-area-info-content-icon {
        width: 60%;
        padding-bottom: 60%;
    }

}

// not social context ///////////////////////////////////

.demographic:not(.social) {

    @include chart-reserve( false, (
            "small": 40,
            "large": 75
    ));
}

// social context ///////////////////////////////////////

.demographic.social {

    @include chart-reserve( true, (
        "small": 100,
        "large": 300
    ));

    .demographic-bar-chart {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin: 16px;
        padding-bottom:0;

        &.loading {
            margin:0;
        }

    }

    .demographic-bar-chart-header {
        margin-top: 0;
        height: auto;
    }

    .demographic-bar-chart-area {
        margin: 0;
    }

    .demographic-bar-chart-area-chart {
        position: relative;
    }

    .demographic-bar-chart-area-chart-reserve {}

    .demographic-bar-chart-area-chart-reserve-info-under-bar {
        height: 100%;
        width: 100%;
    }

    .demographic-bar-chart-area-chart-reserve-under {

        position: absolute;
        top: 22px;
        left: 0;
        height: calc(100% - 46px);
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        flex-direction:column;
        width: 100%;

        > div:nth-child( odd ) {
            background: rgba(100, 100, 100, 0.05);
        }

    }

    .demographic-bar-chart-area-chart-reserve-info-title,
    .demographic-bar-chart-area-chart-header-column:nth-last-child(1) {

        display: grid;
        grid-template-columns: repeat(4, 1fr);
        text-align: center;

        > div:nth-last-child(1) {
            text-align: left;
        }

    }

    .demographic-bar-chart-area-chart-reserve-info-title {

        transform: translateX(0);

        > div {
            justify-content: center;
            align-content: center;
        }

        > div:nth-last-child(1) {
            &:after {
                content: '%';
                font-size: 10px;
            }
        }

    }

    .demographic-bar-chart-area-chart-header-column {

        > div svg {
            height: 16px;
            width: auto;
        }

        &:nth-last-child(1) {
            > div:nth-last-child(1) {
                padding-left: 8px;
            }
        }

    }

    @media only screen and ( max-width: 800px ) {

        .demographic-bar-chart-area-chart-reserve-info-title {
            > div:nth-last-child(1) {
                &:after {
                    font-size: 6px;
                }
            }
        }

    }

}
