#admin-header {
  height: 64px;

  // LOGO
  #header-logo {
    margin-left: 16px;
  }

  // WELCOME MENU TOP RIGHT
  #welcome {
    font-weight: 600;

    @include mobile {
      justify-content: flex-end;
    }

    font-weight: 600;
    padding-right: 28px;
    text-align: right;
    display: flex;
    justify-content: right;

    #welcome-text {
      cursor: pointer;
      display: grid;
      grid-template: 1fr/3fr 0.1fr;
      align-items: center;
      grid-gap: 10px;

      @include mobile {
        display: none;
      }

      .fa-caret-down {
      }
    }

    // MOBILE TOP RIGHT BUTTON
    #mobile-welcome-button {
      display: none;

      @include mobile {
        display: flex;
        padding: 15px;
        margin-right: 15px;
      }
    }
  }

  // DROPDOWN
  #dropdown-background {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    #dropdown {

      position: absolute;
      padding: 0px 10px;

      right: 15px;
      top: 70px;
      background: #fff;
      box-shadow: 0 1px 2px 0 rgba(0,0,0,.5);

      &:hover {
        background: #0d2832;
        ul li {
          color: #fff;
          &:first-child::after {
            background-color: #0d2832;
          }
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          font-weight: 400;
          height: 40px;
          color: #000;

          .fa-sign-out-alt {
            margin-right: 15px;
            font-size: 22px;
          }
        }

        a {
          text-decoration: none;
        }

        li:first-child::after {
          content: "";
          position: absolute;
          transform: rotate(45deg);
          width: 10px;
          height: 10px;
          background-color: #fff;
          top: -5px;
          left: calc( 100% - 25px );
          box-shadow: -1px -1px 0 -1px rgba(0,0,0,.5);
        }

        //li:first-child:hover::after {
        //  background: linear-gradient(to right bottom, #fff 50%, #000 50%);
        //}

      }
    }
  }
}