.list-table {

  a {
    text-decoration: none;
    color: inherit;
  }

  .list-table-row.header-row {
    font-weight: 600;
    background-color: inherit;
    margin-top: 0;

    @include mobile {
      display: none !important;
    }
  }

  .list-table-row {
    width: 100%;
    background-color: white;
    margin-top: 4px;
    height: 40px;

    @include mobile {
      flex-direction: column;
      height: initial;
      width: 95%;
      margin: 2.5%;
      padding: 5px 0;
    }

    .list-table-col {
      padding: 5px 10px;
      min-width: 200px;

      .mobile-col-header {
        display: none;
      }

      @include mobile {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;

        span {
          display: inline-block !important;
        }

        .mobile-col-header {
          display: none;
          font-weight: 700;
          margin-right: 10px;
        }
      }
    }
  }
}