#top-clicked-and-sold {
  min-height: 605.55px;
  width: 100%;
  margin: 10px;
  background-color: #FFFFFF;

  th, td {
    width: 50%;
  }
}

#top-clicked-and-sold.loading {
  background-color: #e9f3fb;
}