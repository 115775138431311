.top-keywords {
  height: 100%;

  &.loading {
    background-color: #e9f3fb;
    min-height: 622px;

    > .loading-wrapper {
      min-height: 622px;
    }

  }
}