#performance-metrics {
  margin: 20px 10px 10px;
  min-height: 416px;
  background: #FFFFFF;

  .dash-table {

    th {
      width: 14.285714286%;
    }

    .total-row td {
      color: white;
    }
  }
}

.seim-dash-content {
  #performance-metrics {
    min-height: 365px;
  }

  #performance-metrics.loading {
    .loading-wrapper {
      min-height: 365px;
    }
  }
}

#performance-metrics.loading {
  background-color: #e9f3fb;

  .loading-wrapper {
    min-height: 416px;
  }
}