.channels-pie-chart {

    position: relative;
    background: #FFFFFF;
    width: 100%;
    min-height: 250px;
    overflow: hidden;
    z-index:1;

    &.loading {
        background-color: #e9f3fb;
    }

}

.channels-pie-chart-header {
    font-size: 26px;
    text-align: center;
    margin-top: 16px;
    letter-spacing: 0.5px;
}

.channels-pie-chart-area {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    justify-items: center;
    align-items:center;
    align-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.channels-pie-chart-area-chart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.channels-pie-chart-area-chart-middle {

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    overflow: hidden;
    border-radius: 100%;

    display: grid;
    align-items: center;
    text-align: center;

}

.channels-pie-chart-area-chart-middle-text {
    transition: opacity 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94); // easeOutQuad
}

.channels-pie-chart-area-legend {
    display: grid;
    grid-gap: 4px;
    width: 75%;
    font-size: 14px;
}

.channels-pie-chart-area-legend-item {
    display:grid;
    grid-template-columns: 1fr 2fr;
}

.channels-pie-chart-area-legend-item-info {
    display: flex;
    align-items: center;
}

.channels-pie-chart-area-legend-item-info-square {
    width: 20px;
    height: 20px;
    background: green;
    margin-right: 10px;
}