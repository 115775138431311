@import 'variables';
@import 'fonts';
@import 'admin/header';
@import 'login';
@import 'dashboard/dash';
@import 'management/dialogs';

@page {
  size: auto;
  margin: 0.125in 0.125in 0.125in 0.125in;
}


html, body, :root {
  margin: 0;
  min-height:100vh;
  background:transparent;
}

html, body, #root, #root .app-container {
  min-height:100vh;
}

:root{
  font-size: 68%;
  --altBackground:#0a262f;
  --mainBackground:white;
  --mainBackground-color:var(--altBackground);
}

:root {
  background: var(--mainBackground-color);
  transition: background 100ms ease-in;
}

html {

  body {

    #root {

      @import 'dashboard/puppeteer';
      @import 'dashboard/loading';
      @import 'admin/admin';

      #management {
        min-height:100vh;
        @import 'management/variables';
        @import 'management/app';
        a, a:hover {
          color: unset;
          text-decoration:none;
        }
        // background: white;
        height:100%;
        .management-scroll {
        }
      }


      .app-container {

        padding: 0;
        @import 'admin/sidebar';
        @import 'admin/users';
        @import 'admin/makes-metrics';
        @import 'admin/clients';
        @import 'admin/table';
        @import 'admin/tabs';
        @import 'admin/entity_details';
        @import 'admin/cpm-goals';
        @import 'admin/popup';
        @import 'admin/user-permissions';
        @import 'dashboard/general';
        @import 'dashboard/header';
        @import 'dashboard/mini';
        @import 'dashboard/conversions';
        @import 'dashboard/impression-share';
        @import 'dashboard/targeting';
        @import 'dashboard/monthly';
        @import 'dashboard/cpm-display';
        @import 'dashboard/ppc-display';
        @import 'dashboard/top-vehicles';
        @import 'dashboard/monthly';
        @import 'dashboard/sidebar';
        @import 'dashboard/reporting_header';
        @import 'dashboard/performance-metrics';
        @import 'dashboard/adgroup-performance';
        @import 'dashboard/keyword-performance';
        @import 'dashboard/dash-table';
        @import 'dashboard/sub-header-bar';
        @import 'dashboard/top-clicked';
        @import 'dashboard/top-clicked-and-sold';
        @import 'dashboard/post-impression';
        @import 'dashboard/trends_chart';
        @import 'dashboard/channels_pie_chart';
        @import 'dashboard/campaign-breakdown';
        @import 'dashboard/top-organic-traffic';
        @import 'dashboard/quartile-bar-chart';
        @import 'dashboard/demographic-bar-chart';
        @import 'dashboard/campaign_performance_metrics';
        @import 'dashboard/trueview-campaign-breakdown';
        @import 'dashboard/top-keywords';
        @import 'dashboard/top-landing-pages';
        @import 'dashboard/blog-posts';
        @import 'dashboard/asana-tasks';
        @import 'dashboard/percent-bar';
        @import 'dashboard/campaign-performance';
        @import 'dashboard/modal';
        @import 'dashboard/vdp-device-breakdown';
        @import 'dashboard/campaign-performance-device-breakdown';
        @import 'dashboard/fan-growth';
        a, a:hover {
          color: unset;
          text-decoration:none;
        }
        .banner-dash {
          background-color: mdgv( $colors, 'hex', 'secondary' );
          text-transform: uppercase;
          text-align:center;
          font-weight:400;
          color: white;
          font-size:14px;
          margin:10px;
          padding:8px;
        }

        .banner-dash-organic {
          margin-top: 20px;
        }

        .d-flex.break-half-with-quarter-charts {

          > div, > .d-flex {
            width:50%;
          }

        }

        .d-flex.four-across-break-half {
          flex-wrap: wrap;
          > div, > .d-flex {
            width: calc( 25% - 20px );
          }
        }

        .d-flex.three-across-two-rows {
          display:grid!important;
          grid-template: repeat(2,1fr) / repeat(3,1fr);
          .mini-widget {
            width:auto;
            height:225px;
          }
        }

        .d-flex.three-across-repeat-two {
          display:grid!important;
          grid-template: 1fr / 2fr repeat( 2, 1fr );
          .mini-widget {
            width:auto;
            height:225px;
          }
        }

        @media only screen and ( max-width: 1299px ) {
          .d-flex.four-across-break-half {
            > div, > .d-flex {
              width:calc( 50% - 20px);
            }
          }
          .d-flex.break-half-with-quarter-charts {
            flex-direction: column;
            > div {
              width: 100%;
            }
            .d-flex {
              width: 100%;
            }

          }
        }

        @media only screen and ( max-width: 845px ) {
          .d-flex.break-third {
            flex-direction: column;
          }
          .d-flex.three-across-two-rows {
            grid-template: repeat(2,1fr) / repeat(2,1fr);
          }
          .d-flex.three-across-repeat-two {
            grid-template: 2fr / 2fr;
          }
        }

        @media only screen and ( max-width: 588px ) {

          .d-flex.four-across-break-half {
            flex-direction: column;
            > div, .d-flex {
              width: calc( 100% - 20px )!important
            }
          }
          .d-flex.break-half-with-quarter-charts {
            .d-flex {
              flex-direction: column;
              > div {
                width: calc( 100% - 20px )!important;
              }
            }
          }
          .d-flex.three-across-two-rows {
            grid-template: repeat(1,1fr) / repeat(1,1fr);
          }
        }

        // $color1: mdgv( $colors, 'hex', 'main' );
        // $color2: mdgv( $colors, 'rgb', 'secondary' );
        // $color3: #{ var( mdg($colors, 'rgb', 'secondary' ) )};
        // $color4: rgba( var( mdg($colors,'rgbObj','secondary')),1);

        // $color5: lighten( rgba( var( mdg($colors,'rgbObj','secondary')), 0.8 ), 20%);
        // $color5: won't work because lighten is SASS color! we need css4 color Func
        // https://colorme.io/

        #body-wrapper {

          margin: 0;
          // background: white;

          #content {
            padding: 0;
          }
        }

      }
    }
  }
}

@media only print {

  html, body, #root .app-container {
    height: auto;
  }

}