.quartile-bar-chart {

    position: relative;
    margin: 10px;
    background: #FFFFFF;
    width: 100%;
    min-height: 250px;
    overflow: hidden;
    padding-bottom: 32px;

    &.loading {
        background-color: #e9f3fb;
    }

}

.quartile-bar-chart-header {
    font-size: 26px;
    text-align: center;
    margin-top: 16px;
    height: 76px;
    letter-spacing: 0.5px;
}

.quartile-bar-chart-area {
    position:relative;
    display: flex;
    align-content: center;
    justify-content: center;
}

.quartile-bar-chart-area-chart,
.quartile-bar-chart-area-info {
    position: relative; // required by charts.js
    width: 20vw * 2.55;
    height: 20vw;
}

.quartile-bar-chart-area-info  {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate3d(-50%,0,0);
    z-index:2;
}

.quartile-bar-chart-area-info-container {

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    height:100%;
    // fit the chart:
    margin-left: 42px;
    width: calc( 51vw - 42px );
    padding-right: 8px;

}

@keyframes quarterIn {
    100% {
        opacity: 1;
    }
}
.quartile-bar-chart-area-info-container-quarter {
    opacity: 0;
    display: flex;
    align-self: end;
    margin-bottom: 24px;
    color: white;
    font-weight: lighter;
    animation-fill-mode: forwards;
    animation-name: quarterIn;
    animation-delay: 500ms;
    animation-iteration-count: 1;
    animation-duration: 200ms;
    animation-timing-function: ease-in;
}


@media only screen and ( max-width: 800px )  {

    .quartile-bar-chart-area-info-container-quarter {
        font-size: 8px;
    }
}

@media only screen and ( max-width: 530px )  {
    .quartile-bar-chart-area-info-container-quarter {
        font-size: 4px;
    }
}