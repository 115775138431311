.top-landing-pages {
  width: 100%;

  table {
    table-layout: fixed;
  }

  .dash-table-header th {
      min-width: 125px;
  }

  &.loading {
    background-color: #e9f3fb;
    min-height: 389px;
  }
}