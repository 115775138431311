#login {
  // background: #0d2832;
  width: 100vw;
  height: 100vh;

  .login-container {
    display: grid;
    margin-top: 10px;
    grid-gap: 10px;
  }

  form {
    max-width: 90%;

    #panel {
      background-color: #fff;
      color: #0d2832;
      height: 240px;
      width: 390px;
      border-radius: 5px;
      max-width: 100%;

      @include mobile {
        height: 342px;
      }

      img {
        margin: 7px auto;
        display: block;
      }

      .input-group {
        height: 52px;
        border-top: 1px solid hsla(230, 6%, 80%, .7);
        font-size: 14px;

        @include mobile {
          height: 104px;
        }

        * {
          padding-left: 15px;
        }

        div {
          width: 112px;

          @include mobile {
            width: 100%;
            height: 51px;
          }
        }

        input {
          width: 278px;
          border: 0 none;
          background-color: #f6f7f8;
          color: #0d2832;

          @include mobile {
            width: 100%;
            height: 51px;
          }
        }

        input:focus {
          background-color: rgba(82, 177, 202, .5);
          transition: background .2s ease-in-out;
          outline: 0;
        }
      }

      .input-group:nth-of-type(2) {
        border-bottom: 1px solid hsla(230, 6%, 80%, .7);
      }

      #login-error {
        text-align: center;
        font-size: 11px;
        padding: 5px 2px;
        background: #e53644;
        color: #fff;
      }
    }
  }

  .btn {
    height: 50px;
    outline: none;
    font-size: 14px;
  }

  .btn:focus {
    box-shadow: none;
  }

  #submit {
    background: #53b0c9;
    color: #fff;
    transition: background .3s ease-in-out;
  }

  #submit:hover {
    background: #2559b4;
    transition: background .3s ease-in-out;
  }
}