// top level
#impression-share {

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  background: #FFFFFF;
  height: 380px;
  min-width: 277.5px;
  z-index: 1;

  .impression-chart-area {
    position: relative;
    display: flex;
    width: 200px;
    padding-bottom: 200px;
  }

  //under
  .impression-header {
    font-size: 26px;
    text-align: center;
    margin-top: 16px;
    height: 63px;
    letter-spacing: 0.5px;
  }

  .middle, canvas {
    position: absolute;
    top: 49%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);
    overflow: hidden;
    border-radius: 100%;
  }

  .middle {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2372b0;
    z-index: -1;

    .text {
      padding: 10%;
      text-align: center;
      color: white;
      letter-spacing: 0.5px;

      span {
        font-size: 18px;
      }

      .percent {
        position: relative;
        bottom: 5px;
        font-size: 30px;
        font-weight: bold;
      }
    }
  }

  .impression-competitors {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: #88aaaa;
    font-weight: bold;
    font-size: 20px;
    transform: translateY(100%);

    .percent {
      position: relative;
      bottom: 6px;
    }
  }
}

#impression-share.loading {
  background-color: #e9f3fb;
}