#tabs {
  height: 48px;
  font-weight: 400;
  font-size: 16px;

  .tabs-cell {
    background: #0d2832;
    color: white;
    height: 100%;
    margin-left: 3px;
    width: 227px;
    cursor: pointer;
    border-radius: 2px 2px 0 0;

  }

  .tabs-cell.active {
    color: #52b1ca;
    background: #fff;

  }

  .tabs-cell:first-of-type {
    margin-left: 0;
  }
}

#tab-content {
  background-color: white;
  padding: 32px 27px 40px 22px;

}