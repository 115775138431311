.asana-tasks {
  width: 100%;

  table {
    table-layout: fixed;
    width:100%;
  }

  td, tr {
    border:0;
    padding:0;
    margin:0;
  }

  tr {
    width: 100%;
  }

  td {
    width: 50%;
  }

  .asana-task {
    border-left: 6px solid #00333d;
    border-radius: 3px 0 0 3px;
    width: calc( 100% - 6px );
    max-width: 590px;
    height: 75px;
    margin: 8px 0;
    overflow-y: hidden;
    overflow-x: auto;

    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);

    .asana-title {
      font-size: 18px;
    }

    .asana-description {
      font-size: 12px;
      margin-top: 5px;
      color: rgba(5,32,38,0.3);
    }

    .col {
      width: 87%;
    }

    .circle-icon-wrapper {
      height: 100%;
      width: 13%;
      svg {
        font-size: 28px;
      }
    }
  }

  &.loading {
    background-color: #e9f3fb;
    .loading-wrapper {
      min-height: 215px;
    }
  }
}