.tv-campaign-breakdown {

    position: relative;
    margin: 10px;
    background: #FFFFFF;
    width: 100%;
    min-height: 150px;
    overflow: hidden;

    &.loading {
        background-color: #e9f3fb;
        > div {
            min-height: 250px;
        }
    }

    .dash-table .dash-table-header td {
        color: white;
        font-weight: lighter;
    }

    .campaign-breakdown-hide-show-button {
        background-color: transparent;
        outline: none;
        border: 0;
        color: deepskyblue;
        font-size: 24px;
        cursor: pointer;
    }

    td span {

        &.spacer {
            display:inline-block;
            margin-right:2px;
            margin-left: 2px;
        }

        &.up {
            color: green;
        }

        &.down {
            color: darkred;
        }

        &.spend,
        &.cpc,
        &.cpv {
            &.up {
                color: darkred;
            }
            &.down {
                color: green;
            }
        }

    }

    tbody td {
        font-size: 12px;
    }

    .hidden-row {

        background-color: rgb(242,245,248);

        td {
            padding: 0 !important;
            border-bottom-width: 0 !important;
        }

        .video-grid {
            display: grid;
            grid-template-columns: repeat( 1, 1fr );
            padding-left: 48px;
            padding-right: 48px;
        }

        .overflow-div {

            overflow: hidden;
            margin:0;
            transition: margin 300ms ease-in;

            .content-div {

                display: flex;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                flex-flow: column;

                position:relative;
                opacity: 0;
                height: 0;
                padding: 0;
                border-bottom: 0;
                transform: translateY( -100% );
                transition: transform 200ms ease-in;
                transition-property: opacity, border-bottom, transform;

            }
            .video-div {
                position:relative;
                background: rgb(242, 248, 254);
                height: 0;
                width: calc( 33% * 16/9 );
                padding-bottom: 0;
                transition: padding-bottom 200ms ease-in;
                overflow:hidden;
                img {
                    position:absolute;
                    top:50%;
                    left:50%;
                    transform: translate3d(-50%,-50%,0);
                    width: 100%;
                }

                iframe {
                    position:absolute;
                    top:0;
                    right:0;
                    bottom:0;
                    left:0;
                    width: 100%;
                    height: 100%;
                }

            }


        }

        &.show {

            .overflow-div {

                margin: 20px;
                transition-timing-function: ease-out;

            }

            .video-div {
                padding-bottom: 33%;
                margin-bottom: 12px;
            }

            .content-div {
                opacity: 1;
                box-sizing: content-box;
                width: calc( 100% - 48px );
                height: auto;
                transform: translateY( 0% );
                transition-timing-function: ease-out;
            }

        }
    }
}