.current-previous-bar-chart {

    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    margin: 10px;
    padding: 16px;
    background: #FFFFFF;

    > div {
        padding: 10px 0;
    }

    &.loading {
        min-height: 250px;
        background-color: #e9f3fb;
    }

}

.current-previous-bar-chart-header {
    font-size: 26px;
    text-align: center;
    letter-spacing: 0.5px;
}

.current-previous-bar-chart-area {

    &.has-sidestats {

        display: grid;
        grid-template-columns: 5fr 1fr;
        grid-gap: 24px;

        .current-previous-bar-chart-area-sidestats {

            display: flex;
            justify-items: baseline;
            flex-direction: column;
            padding: 16px;

            &:after {
                content: '';
                position: absolute;
                left: auto;
                height: 60%;
                top: 50%;
                width: 1px;
                background-color: rgb(200,200,200);
                transform: translateY(-50%);
                right: 18%;
            }
        }

        .current-previous-bar-chart-area-sidestats-content {
            &.name {
                word-break: break-word;
            }
            &.current {
                color: mdgv($colors,'hex','tertiary');
                font-size: 36px;
            }
            &.previous {
                font-size: 12px;
                color: rgb(150,150,150);
                font-weight: 300;
            }

        }

        @media only screen and ( max-width:1300px ) {

            display: flex;
            flex-direction: column;

            .current-previous-bar-chart-area-sidestats {
                padding: 0;
                align-items: center;
                &:after {
                    content: normal;
                }
            }

        }

    }

}

.current-previous-bar-chart-stats {

    display: grid;
    justify-items: center;

    p {
        margin-bottom: 0;
        text-align: center;
    }

    padding-bottom: 20px;

}

.current-previous-bar-chart-stats-total {

    display: grid;
    grid-gap: 4px;
    grid-template-columns: repeat( 2, 1fr );
    transform: translateX( 3.33% );
    align-items: center;

    p {

        font-size: 12px;
        line-height: 20px;

    }

    span {

        font-size: 16px;
        margin-left: 4px;
        line-height: 20px;

    }

    svg {
        vertical-align: -1px;
    }

}

.current-previous-bar-chart-stats-previous {
    p { color: rgb(180,180,180); }
}

.current-previous-bar-chart-stats-total-indicator.up {
    color: green;
}


.current-previous-bar-chart-stats-total-indicator.down {
    color: darkred;
}

.current-previous-bar-chart-stats-previous {
    color: rgba( 0,0,0,0.3 );
    font-weight: lighter;
}

