#menu- ul, #menu-clients ul {
  padding-right: 0 !important;
  width: 100% !important;
}

.dialog .activations {
  margin-bottom: 19px;
}

// DIALOG

.dialog {
  .paper {
    max-width: none !important;

    h6 {
      margin: 35px 0 0;
    }

    .client-form-row {
      margin: 5px 0 10px;

      .text-input {
        min-width: 215px;
        margin: 0 5px;
      }

      .text-input:first-of-type {
        margin-left: 0;
      }

      .text-input:last-of-type {
        margin-right: 0;
      }
    }
  }
}

#scripts-dialog, #pacing-dialog, #derma-dialog {
  .paper {

    min-width: 650px;

    .short, .clients {
      width: 50%;
    }

    .select-input {
      width: 25%;
    }

    .checkbox-input {
      margin-bottom: 0;
    }

    .text-input:first-of-type {
      margin-top: 0;
    }

    .add-button {
      background-color: #faf2cc;
      color: #8a6d3b;
      margin-top: 35px;
    }

    .add-button:focus {
      outline: none;
    }

    .delete-ad {
      margin-top: 12px;
      margin-left: 5px;
      padding: 5px;
    }

    .delete-ad:hover {
      color: #dc3544d1;
    }

    .delete-ad:focus {
      outline: none;
    }

    .ad-container {
      margin-top: 22px;
    }
  }
}

#keyword-dialog {
  .select-input {
    min-width: 100px;
  }
}

#client-dialog {
  .paper {
    min-width: 950px;
    min-height:450px;
    .text-input {
      min-width: 275px;
    }
  }
}