
.pupp-footer {
    display:none;
    visibility:hidden;
    opacity:0;
    height:0;
    width:0;
    left: -100%;
    bottom: -100%;
    background:transparent;
}

@mixin print {

    div, table, *, canvas, button, a {
        box-shadow: none !important;
    }

    .fake-header {
        display:none;
    }

    #dash-header,
    #dash-sidebar,
    .app-container #dash-sidebar {
        display: none;
        visibility: hidden;
        width: 0;
        min-width: 0!important;
        height: 0;
        opacity: 0;
    }

    #dash-content {
        float: none;
        margin:0 auto!important;
        max-width: 1350px!important;
        background-color:transparent!important;
    }

    #body-wrapper {

        padding-top: 0;

        #dash-main {
            padding-left: 0;
        }

    }

    .box-shadow,
    .asana-task {
        box-shadow:none;
        border: 1px solid #ccc;
    }

    .pupp-print {
        position: relative;
        height: round( 1350px * ( 11 / 8.5 ) );
        overflow: hidden;
    }

    .pupp-print:last-of-type {
        height: round( 1339px * ( 11 / 8.5 ) ); // account for padding-top, -10px
    }

    .pupp-print,
    #dash-content,
    #dash-main,
    #body-wrapper {
        border:0!important;
        box-shadow: none!important;
    }
    #targeting {
        .ui-switch {
            display: none;
        }
    }

    #targeting-extra {
        .showing {
            display:none;
        }
        .flip-campaign-box {
            display: none;
        }
        .campaigns {
            height:90%!important;
            .campaign-box {
                .campaign-box-ul .reset-list-button {
                    display:none;
                }
                &-areas {
                    display: none;
                }
            }
            &.flip {
                .campaign-box {
                    &-campaigns {
                        z-index: 1;
                        transform: rotateY(0deg);
                        pointer-events:auto;
                    }
                    &-areas {
                        display:none;
                        z-index: 2;
                        transform: rotateY(180deg);
                        pointer-events:none;
                    }
                }
            }
        }
        > form, > button {
            display:none;
        }
    }

    .pupp-footer {

        display: flex;
        position: absolute;

        top: calc( 100vh - 76px );
        right: 16px;
        bottom: auto;
        left: auto;

        z-index: 100;

        justify-content: flex-end;
        align-items: flex-end;

        visibility: visible;
        opacity: 1;

        width: 200px;
        height: 32px;

        color: black;
        font-size: 16px;

    }

    .tv-campaign-breakdown {
        .hidden-row {
            .video-grid {
                grid-template-columns: repeat( 2, 2fr );
                &.single {
                    display: flex;
                    width: 100%;
                    align-content: center;
                    justify-content: center;
                    .overflow-div {
                        width: 50%;
                    }
                }
            }
            .overflow-div {
                .video-div {
                    width: calc( 40% * 16/9 );
                    padding-bottom: 40%;
                }
            }

        }
    }

    .social-paid-campaign-breakdown,
    .social-paid-pla-breakdown,
    .social-organic-campaign-breakdown {

        width: auto;

        .ad-creative-wrapper {
            border:1px solid lightgrey;
        }
        .ad-creative-container {
            grid-template: 1fr / repeat( 3, 1fr );
        }

        .ad-creative-social-stat {
            font-size:10px;
        }

        .overflow-div {
            background-color: transparent;
        }

        .overflow-div {
            padding: 20px 10px;
        }

    }

    .image-overflow,
    .pla-overflow {

        .box-shadow {
            border:0!important;
        }

        .overflow-div {
            padding:0;
        }

        .ad-creative-container {
            margin-top: 0;
            margin-right: 50px;
            margin-bottom: 0;
            margin-left: 50px;
        }

        .banner-dash:not(.is-first) {
            font-size: 12px;
            background: rgb(180,180,180);
        }
    }

    .campaign-breakdown.social-organic-campaign-breakdown {
        .dash-table tbody tr:nth-child(odd) {
            background: white;
        }
    }

    .organic-social-dash-content {

        .puppeteer-top-posts-breakdown-grid {

            &.d-flex {
                margin: 10px;
            }

            .content-grid {
                padding: 32px;
                flex: 0 0 100%;
                display: grid;
                grid-gap: 32px;
                grid-template: 1fr / repeat( 2, 1fr );
            }

            .ad-creative-container-organic{
                width: 70%;
            }

            .ad-creative-campaign-name {
                display: block;
            }

        }


    }

    .paid-social-dash-content {

        .campaign-breakdown {
            &.social-paid-campaign-breakdown,
            &.social-organic-campaign-breakdown {
                .ad-creative-content-crop > img {
                    width: 88%;
                }
            }
        }

        .d-flex.three-across-two-rows {
            grid-template: repeat(2, 1fr) / repeat(2, 1fr);
            .mini-widget {
                height: 400px;
            }
        }

    }

    .social-organic-campaign-breakdown {

        .dash-table tr > td:nth-last-child(1) {
            min-width:300px;
        }

        .ad-creative-campaign-name {
            text-align: center;
            padding-bottom: 8px;
        }

        .ad-creative-content-crop {

            display: flex;
            justify-content: center;

            > img {
                max-height: 450px;
                width: auto;
            }

        }
    }

}

#dashboard.app-container.puppeteer-browser {
    @include print;
    border:0!important;
    box-shadow:none!important;
}

@media only print {

    @include print;

    .pupp-print {
        height: auto;
        overflow: visible;
    }

}