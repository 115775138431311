#keyword-performance {
  margin: 10px;
  width: 50%;
  background: #FFFFFF;

  tr:last-child {
    height: 51px;
  }
}

#keyword-performance.loading {
  background-color: #e9f3fb;
  min-height: 617px;
  .loading-wrapper {
    min-height: 617px;
  }
}