.vdp-device-breakdown {
    width: calc( 100% - 20px );
    margin: 10px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 380px;
    min-width: 277.5px;
    z-index: 1;
    background-color: #e9f3fb;
}

.vdp-device-breakdown:not(.loading) {
    background-color: #FFFFFF;
}

.vdp-device-breakdown-area {
    position:relative;
    width: 90%;
    padding-bottom: calc( 380px * 0.75 );
}

.vdp-device-breakdown-area-chart {
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index:0;
}

.vdp-device-breakdown-header {
    font-size: 26px;
    text-align: center;
    margin-top: 16px;
    height: 63px;
    letter-spacing: 0.5px;
}

.vdp-device-breakdown-annotations {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 45px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    z-index:1;
}

.vdp-device-breakdown-annotations-device {

    position:relative;
    display: flex;
    justify-content: space-evenly;
    padding-top: 40px;
    padding-bottom: 30px;
    height:100%;
    width:100%;
    float:left;

    > div {
        position: relative;
        float: left;
        text-align: center;
        font-size: 12px;
        height: 75%;
        top: 15%;
    }
}

@media (max-width:980px) {
    .vdp-device-breakdown-header {
        font-size: 16px;
    }
}