#entity-details {
  .top-entity-bar {
    border-bottom: solid 1px rgb(13, 40, 50);
    margin-bottom: 30px;

    h5 {
      padding: 5px;
    }

    #entity-name {
      margin-left: 20px;
    }

    #edit-link {
      cursor: pointer;
      margin-right: 20px;
      color: rgb(37, 89, 180);

    }

    #edit-link:hover {
      color: rgb(82, 177, 202);
    }
  }

  form {
    input {
      background-color: #f6f7f8;
      margin-bottom: 1em;
      padding: 0 16px;
      height: 50px;
      outline: none;
      font-size: 14px;
      width: 70%;
      border-radius: 0 2px 2px 0;
      border: none;
    }

    input:focus {
      background-color: #798392;
      color: #fff;
      transition: background .2s ease-in-out;
    }

    .percent-sign {
      position: absolute;
      right: 60px;
      bottom: 26px;
    }

    input:focus + .percent-sign {
      color: #fff;
    }

    .label, .checkbox-label {

      display: flex;
      align-items: center;
      width: 30%;
      height: 50px;
      overflow: hidden;
      padding: 2px 24px 2px 12px;
      font-size:18px;
      line-height: 1.3;

    }

    .label {
      background-color: #edeff1;
      color: rgb(121, 131, 146);
      border-radius: 2px 0 0 2px;
      font-size: 15px;
    }

    .checkbox-label {
      color: rgb(121, 131, 146);
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.6px;
    }

    .checkbox-input {
      width: 25%;

      &.disabled {
        .checkbox-square {
          cursor: default;
          border: 2px solid #d3cfcf;

          &.checked {
            background-color: #d3cfcf;
          }
        }

        .checkbox-square-label {
          color: #d3cfcf;
        }
      }

      .checkbox-square {
        width: 25px;
        height: 25px;
        border-radius: 3px;
        border: 2px solid #52b1ca;
        margin-top: 12.5px;
        cursor: pointer;

        &.checked {
          background-color: #52b1ca;
        }
      }

      .checkbox-square-label {
        line-height: 50px;
        padding: 0 20px;
        height: 50px;
        overflow: hidden;
        font-size: 16px;
        letter-spacing: 0.6px;
        color: #52b1ca;
      }
    }

    .label, .checkbox-label, input {
      @include mobile {
        width: 100%;
      }
    }

    .d-flex {
      @include mobile {
        flex-direction: column;
      }
    }
  }

  #error-message {
    margin: 25px 0;

    span {
      color: #e53644;
    }
  }

  .cancel-save {
    @include mobile {
      flex-direction: column;

      #save-button {
        margin: 0 auto 25px;
      }

      #cancel-button {
        margin: 0 auto;
      }
    }
  }

  .btn {
    height: 40px;
    outline: none;
    font-size: inherit;
  }

  .btn:focus {
    box-shadow: none;
  }

  #save-button {
    width: 160px;
    background: #53b0c9;
    color: #fff;
    margin-right: 20px;
    margin-bottom: 25px;
  }

  #save-button:hover, #ok-button:hover {
    background: #2559b4;
    transition: background .3s ease-in-out;
  }

  #cancel-button {
    width: 160px;
    color: #2559b4;
    margin-left: 20px;
    background: #fff;

    @include mobile {
      width: 100px;
      margin-left: 5px;
    }
  }

  #cancel-button:hover {
    color: rgb(82, 177, 202);
  }

  #delete-button {
    background: #e53644;
    width: 100%;
    color: #fff;
    margin-top: 40px;
  }

  #delete-button:hover {
    background: #af212c;
    transition: background .3s ease-in-out;
  }
}