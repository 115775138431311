#reporting-header {

  display: grid!important;
  grid-template: 1fr / repeat(3,1fr);
  flex-direction: row;
  padding: 16px;
  min-height: 121px;

  margin: 0 10px 10px;
  background-color: rgb(49, 158, 240);
  color: #fff;

  #agency-logo-wrapper {

    display: flex;
    align-items: center;

    > img {
      max-width: 200px;
      max-height: 100px;
    }

    #agency-tmg {
      height: 75.86px;
      width: 98.47px;
      margin-left: 20px;
      margin-top: 5px;
    }

    #agency-sb {
      height: 75.86px;
      width: 170.25px;
    }

    #agency-bd {
      height: 75.86px;
      width: 68.83px;
    }

    #agency-white-label {
      width: 100%;
    }

    #agency-zone {
      height: 75px;
      width: 280px;
    }

    #agency-brew {
      height: 76px;
      width: 76px;
      margin-left: 10px;
    }

    #agency-daa {
      max-height:75px;
    }

    #agency-bmf {
      height: 76px;
      width: 144px;
    }

    #agency-mcbs {
      height: 93.5px;
      width: 180px;
    }

  }

  #center-piece {

    display: flex;
    align-items: center;
    flex-direction: column;

    #channel-type {
      height: 36px;
    }

    #client-name {
      font-size: 22px;
      text-align:center;
    }

    #spend-summary {
      font-size: 12px;

      span:nth-of-type(2) {
        color: rgba(255, 255, 255, 0.4);
        margin-left: 20px;
      }
    }

    .font-awesome-header-logo {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 110px;
      font-size: 16px;
      font-weight: 600;
      color: white;
      border-radius: 20px;
      padding: 8px;
      background-color: #aeb1b9;
      span {
        font-size:11px;
        padding-left:8px;
      }
    }

  }

  #reporting-date-range {

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    #current-range {
      font-size: 14px;
    }

    #comparison-range {
      font-size: 10px;
      color: rgba(255, 255, 255, 0.4);
    }
  }
}

#reporting-header.loading {
  background-color: #e9f3fb;
  grid-template: 1fr / 1fr;
}

@media only screen and ( max-width: 650px ) {

  #reporting-header {

    grid-template: repeat(3,1fr) / 1fr;
    flex-direction: column;
    grid-gap: 20px;

    padding: 16px;
    min-height: 121px;

    margin: 0 10px 10px;
    background-color: rgb(49, 158, 240);
    color: #fff;

    #agency-logo-wrapper {
      justify-content: center;
    }

  }

}
