#clients {
  height: 100%;

  #new-client-button {
    border-left: 1px solid grey;
    height: 100%;
    width: 160px;
    padding: 3px 10px 0 14px;

    a {
      color: white;
      text-decoration: none;

      span {
        padding: 10px;
      }
    }
  }

  .settings-icon {
    color: #52b1ca;
    font-size: 41px;
    padding: 7px;
    position: relative;
    top: 1px;
  }

  .settings-icon:hover {
    color: darken(#52b1ca, 25%);
    transition: color .15s ease-in-out;
  }

  .list-table-row:hover {
    background-color: rgba(255,255,255,.75);
  }

  .list-table-row:hover:first-of-type {
    background-color: inherit;
  }

  a + .list-table-col, .list-table-col:nth-of-type(2) {
    text-align: center;
  }

  .list-table-col:first-of-type:hover {
    span {
      font-weight: bold;
    }
  }

  .list-table-col:first-of-type {
    min-width: 155px;
  }

  .complete-reporting-data-icon {
    color: rgba(0,170,85,0.7);
    margin-left: 10px;
  }

  .sync-reporting-data-icon {
    color: rgb(246, 95, 43);
    margin-left: 10px;
    transition: color .2s ease-in-out;
  }

  .sync-reporting-data-icon:hover {
    transition: color .2s ease-in-out;
    color: rgba(246, 95, 43, .6);
    cursor: pointer;
  }

  #navigate-client-container {
     margin: 0 40px;

    .navigate-client-icon {
      color: white;
      font-size: 3rem;
      padding: 5px;
      transition: color .2s ease-in-out;
      margin-left: 20px;
    }

    .navigate-client-icon:hover {
      color: lightgrey;
      transition: color .2s ease-in-out;
      cursor: pointer;
    }
  }
}

#clients.loading {
  .loading-wrapper {
    min-height: calc( 100vh - 64px );
  }
}