$sm-width: 576px;
$md-width: 768px;
$lg-width: 992px;
$xl-width: 1200px;

@mixin mobile {
  @media screen and (max-width: #{$sm-width}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: #{$md-width}) {
    @content;
  }
}

@mixin sm-desktop {
  @media screen and (max-width: #{$lg-width}) {
    @content;
  }
}

@mixin lg-desktop {
  @media screen and (max-width: #{$xl-width}) {
    @content;
  }
}

/// Map deep get
/// @author Hugo Giraudel
/// @access public
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Key chain
/// @return {*} - Desired value
@function mdg($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@function mdgv( $map, $keys... ) {
  @return var( #{ mdg($map, $keys...)} );
}

/// Deep set function to set a value in nested maps
/// @author Hugo Giraudel
/// @access public
/// @param {Map} $map - Map
/// @param {List} $keys -  Key chaine
/// @param {*} $value - Value to assign
/// @return {Map}
@function mds($map, $keys, $value) {
  $maps: ($map,);
  $result: null;

  // If the last key is a map already
  // Warn the user we will be overriding it with $value
  @if type-of(nth($keys, -1)) == "map" {
    @warn "The last key you specified is a map; it will be overrided with `#{$value}`.";
  }

  // If $keys is a single key
  // Just merge and return
  @if length($keys) == 1 {
    @return map-merge($map, ($keys: $value));
  }

  // Loop from the first to the second to last key from $keys
  // Store the associated map to this key in the $maps list
  // If the key doesn't exist, throw an error
  @for $i from 1 through length($keys) - 1 {
    $current-key: nth($keys, $i);
    $current-map: nth($maps, -1);
    $current-get: map-get($current-map, $current-key);
    @if $current-get == null {
      @error "Key `#{$key}` doesn't exist at current level in map.";
    }
    $maps: append($maps, $current-get);
  }

  // Loop from the last map to the first one
  // Merge it with the previous one
  @for $i from length($maps) through 1 {
    $current-map: nth($maps, $i);
    $current-key: nth($keys, $i);
    $current-val: if($i == length($maps), $value, $result);
    $result: map-merge($current-map, ($current-key: $current-val));
  }

  // Return result
  @return $result;
}

@function rgbObj($hex) {
  @return red($hex), green($hex), blue($hex);
}

$colors: (

  hex: (
    main: --hex-main,
    middle: --hex-middle,
    secondary: --hex-secondary,
    tertiary: --hex-tertiary
  ),

  rgb: (
    main: --rgb-main,
    middle: --rgb-middle,
    secondary: --rgb-secondary,
    tertiary: --rgb-tertiary
  ),

  rgbObj: (
    main: --rgbObj-main,
    middle: --rgbObj-middle,
    secondary: --rgbObj-secondary,
    tertiary: --rgbObj-tertiary
  )

);