.mini-widget {

  height: 180px;
  width: calc( 50%  - 20px );
  margin: 10px;
  background-color: #FFFFFF;
  padding: 10px;
  canvas {
    max-width:100%;
  }
  .widget-metrics-container {
    color: rgba(5, 32, 38, 0.9);
    height: 50%;
    padding-left: 2%;
    letter-spacing: 0.5px;
    overflow: visible;

    .widget-header {
      font-size: 14pt;
      line-height: 22px;
    }

    .comparison-widget-metrics {
      color: rgba(5, 32, 38, 0.3);
      font-size: 12pt;
      vertical-align: middle;
      line-height: 20px;

      .suffix-text-span {
        font-size: .7rem;
        position: relative;
        bottom: 1px;
      }
    }

    .current-widget-metrics {
      margin-top: 2%;
      font-size: 22pt;
      line-height: 34px;

      flex-direction: row;
      width: 50%;

      .suffix-text-span {
        font-size: 1rem;
      }

      .change-in-metrics {
        height: 27px;
        margin-left: 5%;
        border-radius: 6px;
        font-size: 10pt;
        padding: 6px;
        line-height: 14px;
        .suffix-text-span {
          font-size:8pt;
        }
      }

      .change-in-metrics.positive {
        border: 1px solid rgba(0, 170, 85, 0.7);
      }

      .change-in-metrics.negative {
        border: 1px solid rgba(170, 17, 17, 0.7);
      }
    }
  }

  .widget-graph-container {
    position:relative;
    height: 50%;
  }
}

.seim-dash-content {
  .mini-widget {
    width: calc(33.3333% - 20px);
    max-width: calc(33.3333% - 20px);
  }

  .second-row {
    .mini-widget {
      width: calc(50% - 20px);
      max-width: calc(50% - 20px);
    }
  }
}

.seo-dash-content, .trueview-dash-content, .social-paid-dash-content {
  .mini-widget {
    width: calc(33.3333% - 20px);
    max-width: calc(33.3333% - 20px);
  }
}

.cpm-display-dash-content {
  .mini-widget {
    width: calc(33.3333% - 20px);
    max-width: calc(33.3333% - 20px);
  }

  .mini-widget.flat {

    .widget-metrics-container {
      height: 100%;

      .change-in-metrics {
        width: 62px;
        margin-right: 20px;
        text-align: center;
        color: white;
        border: none;
      }
    }

    .widget-graph-container {
      height: 24px;
      margin-top: 10px;
    }
  }
}

.ppc-display-dash-content {
  .mini-widget {
    width: calc(25% - 20px);
    max-width: calc(25% - 20px);
  }
}

.mini-widget.loading {
  background-color: #e9f3fb;
}