@mixin header {
  background-image: linear-gradient(to top,#F0F0F0,#FBFBFB);
  border-bottom: solid 1px rgba(151,151,151,.25);
  box-shadow: 0 1px 2px 0 rgba(140,140,140,0.22);
}

.header {

  @include header;

}

.fake-header {

  @include header;
  min-height: 80px;
  position:relative;

}

#dash-header {

  @import '../admin/select';
  @include header;


  min-height: 80px;
  width: 100%;
  z-index: 2;
  position: fixed;

  display: grid;
  grid-template: 1fr / 5fr 5fr 1fr;
  align-items: center;

  &.sibling-dealer-header {
    grid-template: 1fr / 2fr 4fr 4fr 1fr;

    .sibling-dropdown-wrapper {
      position: relative;
      max-width: 500px;

      select {
        -webkit-appearance: none;
      }

      #thumbtack {
        position: absolute;
        font-size: 18px;
        color: #6d7177;
        right: 10px;
        bottom: 9px;
      }
    }

    .sibling-dropdown-placeholder {
      * {
        border-radius: 2px;
      }

      span {
        background: #6d7177;
        opacity: .25;
        height: 14px;
        width: 100px;
      }
      div {
        background: white;
        height: 35px;
        max-width: 500px;
      }
    }
  }
  // LOGO
  #header-logo {
    margin-left: 16px;
  }

  .dash-header-print-pdf {

    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    padding:12px;

    .pdf-bar {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }

    .pdf-loading-dots {
      transform: translateY(12px);
    }

    &.loading {
      justify-content: center;
      align-self: center;
    }



  }

  #date-pickers {

    display:flex;
    justify-content: center;
    align-items: center;

    .period-text {
      font-size: 12px;
      letter-spacing: 0.5px;
      color: #6d7177;
      padding-left: 7px;
    }

    .date-picker {

      position:relative;
      height: 36px;
      margin-right: 5px;
      margin-left: 5px;

      .icon-calendar-date-picker {

        pointer-events: none;
        position: absolute;
        right: 10px;
        top: 50%;
        font-size: 18px;
        color: #6d7177;
        z-index: 2;
        transform: translateY(-63%);;

      }

      .DateRangePicker {
        height: 37px;
        z-index: 2;

        .DateRangePickerInput {
          height: 36px;
          border-radius: 0;
          border: none;
          border-bottom: 1px solid rgb(220, 220, 220);

          .DateInput_1 {
            width: 81px;

            .DateInput_input_1 {
              height: 31px;
              cursor: pointer;
              font-weight: 400;
              font-size: 12px;
              letter-spacing: 1.3px;
              border: none;
              padding: 0;
              text-align: center;
              position: relative;
              bottom: 1px;
              margin-top: 3px;
            }

            .DateInput_input__focused {
              background-color: #b8e5fc;
              border-radius: 3px;
            }
          }

          .DateRangePickerInput_arrow + .DateInput_1 {
            margin-right: 35px;
          }

          .DateInput_1:first-of-type {
            margin-left: 10px;
          }

          .DateRangePickerInput_arrow {
            position: relative;
            bottom: 1px;
            margin: 0 3px;
          }
        }

        div {
          max-height: 100%;
        }

        .CalendarMonth_caption {
          padding-bottom: 42px;
        }

        .DayPickerNavigation_button {
          max-height: initial;
        }

        .DayPicker_weekHeader_li {

          small {
            color: #757575;
            font-size: 10px;
          }
        }

        .CalendarMonth_table {
          .CalendarDay {
            font-size: 12px;
          }

          .CalendarDay:focus {
            outline: none;
          }
        }

        // LEGEND

        .legend {
          padding: 10px 0;

          .legend-text {
            font-size: 12px;
            color: rgb(72, 72, 72);
            margin: 0 15px;
          }

          .legend-color-block {
            width: 10px;
            height: 10px;
            display: inline-block;
          }

          .legend-color-block.light {
            background-color: rgb(157, 209, 248);
          }

          .legend-color-block.dark {
            background-color: rgb(111, 177, 226);
          }
        }
      }
    }

    // DAY COLORS FOR CURRENT PERIOD
    .date-picker.current {

      .CalendarMonth_table {

        .CalendarDay__selected_start, .CalendarDay__selected_span, .CalendarDay__selected_end {
          background: #9DD1F8;
          border: 1px double #319EF0;
        }

        .CalendarDay__selected_span {
          border: 1px double #319EF0;
        }

        .CalendarDay__selected_start, .CalendarDay__selected_end {
          background: #319EF0;
        }

        .CalendarDay__highlighted_calendar {
          background: #6FB1E2;
          color: #fff;
          border: 1px double #fff;
        }
      }
    }

    // DAY COLORS FOR COMPARISON PERIOD
    .date-picker.comparison {

      .DateRangePicker_picker_1 {
        left: -186px !important;

        .CalendarMonth_table {
          border-collapse: collapse;
          border-spacing: 0;

          .CalendarDay__selected_start, .CalendarDay__selected_span, .CalendarDay__selected_end {
            border: 1px double #2373B0;
            color: #fff;
          }

          .CalendarDay__selected_span {
            background: #6FB1E2;
          }

          .CalendarDay__selected_start, .CalendarDay__selected_end {
            background: #2373B0;
          }

          .CalendarDay__highlighted_calendar {
            background: #9DD1F8;
            color: #fff;
            border: 1px double #fff;
          }
        }
      }
    }
  }
  @media only screen and ( max-width: 1036px ) {
    grid-gap: 20px;
    grid-template: 1fr / 1fr;
    align-items: center;
    flex-wrap: wrap;
    padding:20px;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .dash-header-print-pdf {
      justify-content: center;
      align-self:center;
    }

  }

  @media only screen and (max-width: 501px ) {
    #date-pickers {
      flex-direction: column;
    }
  }

  @media only screen and ( min-width: 1037px ) {
    .header-link {
      align-self: center;
      justify-self: flex-start;
      padding-right: 16px;
    }
  }

}

.pdf-button {
  outline: none;
  border: 1px solid #ccc;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  color: rgba(0, 100, 140, 0.85);
  cursor: pointer;
  border-radius: 4px;
  background-color: #e1effa;
  line-height:1;
  padding: 10px 14px;
  @media only screen and (max-width: 1037px) {
  }

}
