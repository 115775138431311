.half {
    display: flex;
    width: 50%;
    margin: 10px;
    background-color: #FFFFFF;
}

.full {
    width: calc(100% - 20px);
    margin: 10px;
    background-color: #FFFFFF;
}

.capitalize {
    text-transform: capitalize;
}

.text-left {
    text-align:left;
}