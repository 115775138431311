.cpm-goals {

  input {
    background-color: #f6f7f8;
    margin-bottom: 1em;
    padding: 0 16px;
    height: 50px;
    outline: none;
    font-size: 14px;
    width: 70%;
    border-radius: 0 2px 2px 0;
    border: none;
  }

  input:focus {
    background-color: #798392;
    color: #fff;
    transition: background .2s ease-in-out;
  }

  .label {
    background-color: #edeff1;
    color: rgb(121, 131, 146);
    line-height: 50px;
    padding: 0 20px;
    width: 30%;
    height: 50px;
    font-size: 15px;
    border-radius: 2px 0 0 2px;
    overflow: hidden;
  }

  .label, input {
    @include mobile {
      width: 100%;
    }
  }

  .d-flex {
    @include mobile {
      flex-direction: column;
    }
  }

  button[disabled]:hover {
    background-color: #53b0c9 !important;
  }
}