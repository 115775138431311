#admin {
  height: calc(100% - 64px);

  #body-wrapper {

    height: calc( 100% - 64px );
    min-height: calc( 100% - 64px );

    padding-top: 0 !important;

    .blue-bar {
      background-color: #52b1ca;
      height: 64px;
      box-shadow: 0 3px 5px -2px hsla(0,0%,40%,.5);
      position: relative;
      z-index: 1;

      @include mobile {
        height: 44px;
      }
    }

    .content-wrapper {
      background-color: #edeff1;
      height: calc(100% - 64px);
      padding: 25px;
      overflow: scroll;

      @include mobile {
        padding: 0;
        height: calc(100% - 44px);
      }
    }
  }
}