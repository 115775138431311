.dash-table {

  width: 100%;
  font-size: 15px;
  text-align: center;
  padding-bottom: 20px;
  background: #FFFFFF;

  .dash-table-title {
    font-size: 20px;
    color: white;
    text-transform: capitalize;
    background-color: mdgv($colors,'hex','tertiary');
    th {
      padding: 10px;
    }

  }

  .dash-table-header {
    color: white;
    font-size: 15px;
    text-transform: capitalize;
    background-color: mdgv($colors, 'hex','main');
    th {
      padding: 17px;
    }

  }

  td {
    color: rgba(5,32,38,0.9);
    border-bottom: 1px solid #DADADA;
    padding: 14px;
    text-transform: capitalize;
  }

  th {
    font-weight: 300;
  }

  .total-row {
    td {
      padding: 16px;
    }
  }

  &-half {
    float: left;
    width: 50%;
  }

  &-collapse {

    &-right {
      tbody tr {
        border-right:1px solid gray;
        @media only screen and ( max-width: 1000px ) {
          border-right:0;
        }
      }

    }

    &-left {
      tbody tr {
        border-left:1px solid gray;
        @media only screen and ( max-width: 1000px ) {
          border-left:0;
        }
      }
    }

    @media only screen and ( max-width: 1000px ) {
      width: 100%;
      clear: both;
    }
  }

}
