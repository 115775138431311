.popup {
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;

  #modal {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    border-radius: 2px;
    background-color: #fff;
    max-width: 90%;

    span {
      text-align: center;
      padding: 0 25px;
    }

    #ok-button {
      width: 160px;
      background: #53b0c9;
      color: #fff;
      margin-right: 0;
      margin-bottom: 25px;

      @include mobile {
        width: 150px;
      }
    }

    #ok-button:hover {
      background: #2559b4;
      transition: background .3s ease-in-out;
    }
  }
}

#confirm-popup {
  #modal {
    width: 300px;
    height: 204px;

    span {
      margin-top: 15px;
    }

    #cancel-button {
      margin-right: 0;
      margin-bottom: 25px;
    }
  }
}

#create-popup {
  #modal {
    width: 450px;
    height: 182px;

    span {
      margin-top: 15px;
    }

    #cancel-button {
      margin-right: 0;
      margin-bottom: 25px;
    }
  }
}

#delete-popup {
  #modal {
    width: 450px;
    height: 182px;

    span:first-of-type {
      margin-top: 15px;
    }

    #cancel-button {
      margin-right: 0;
      margin-bottom: 25px;
    }

    #ok-button {
      background: #e53644;
      color: #fff;
    }

    #ok-button:hover {
      background: #af212c;
      transition: background .3s ease-in-out;
    }
  }
}