.top-organic-traffic {

    position: relative;
    background: #FFFFFF;
    width: 100%;
    min-height: 250px;
    overflow: hidden;
    padding-bottom: 42px;

    &.loading {
        background-color: #e9f3fb;
    }

    .spacer {

        display:inline-block;
        margin-left:4px;
        margin-right: 4px;

        &.spacer {
            display:inline-block;
            margin-right:2px;
            margin-left: 2px;
        }

        &.up {
            color: green;
        }

        &.down {
            color: darkred;
        }

    }
}

.top-organic-traffic-capitalize {
    text-transform: capitalize;
}

.top-organic-traffic-prev {
    font-size: 9px;
}