#dashboard {

  height: 100%;

  .box-shadow {
    box-shadow: rgb(204, 204, 204) 0 0 15px;
    > div {
      overflow:hidden;
    }
  }

  .top-icon {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    padding: 10px;
    background-color: #ccc;
    color: white;
    margin: 4px;
    font-size: 10px;
    font-weight: 900;

    svg, img {

      width: 18px;
      height: auto;
      margin-right: 6px;

    }

  }

  #body-wrapper {

    #dash-main {

      width: 100%;
      padding-left: 80px;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      #dash-content {
        max-width: 1200px;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.18);
        background-color: rgb(233, 243, 251);
        margin: 10px auto;
        padding-top: 10px;
      }
    }
  }
}