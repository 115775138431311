.cmp-perf-metrics {

    position: relative;
    background: #FFFFFF;
    width: calc( 100% - 20px );
    overflow: hidden;
    padding-bottom: 42px;
    margin: 10px;

    &.loading {
        background-color: #e9f3fb;
    }

    .spacer {

        display:inline-block;
        margin-left:4px;
        margin-right: 4px;

        &.spacer {
            display:inline-block;
            margin-right:2px;
            margin-left: 2px;
        }

        &.up {
            color: green;
        }

        &.down {
            color: darkred;
        }

    }
}

.cmp-perf-metrics-prev {
    font-size: 9px;
}