
@keyframes bar-in {

    100% {
        opacity: 1;
    }

}

.percent-bar {

    position: relative;
    background: mdgv( $colors, 'hex', 'main' );
    height:6px;
    opacity: 0;
    overflow:hidden;
    animation-name: bar-in;
    animation-duration: 200ms;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-direction: normal;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left:0;
        width:100%;
        transform: translate3d( var(--percent-bar-width), 0, 0 );
        height:100%;
        background: mdgv( $colors, 'hex', 'middle' );
        z-index:1;
    }

}