@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot');
  src:  url('fonts/icomoon.eot#iefix') format('embedded-opentype'),
  url('fonts/icomoon.woff2') format('woff2'),
  url('fonts/icomoon.ttf') format('truetype'),
  url('fonts/icomoon.woff') format('woff'),
  url('fonts/icomoon.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar-date-picker:before {
  content: "\e90f";
}
.icon-sem-arrow-glass:before {
  content: "\e904";
}
.icon-seim-barchart:before {
  content: "\e901";
}
.icon-cpc-cpm-display:before {
  content: "\e902";
}
.icon-seo-glass:before {
  content: "\e900";
}
.icon-trueview:before {
  content: "\e911";
}
.icon-power-circular-button:before {
  content: "\e90e";
}
.icon-phone-calls:before {
  content: "\e90a";
}
.icon-document-page:before {
  content: "\e90b";
}

//.icon-login-dropdown:before {
//  content: "\e90d";
//}
//.icon-social-people:before {
//  content: "\e903";
//}
//.icon-other-devices:before {
//  content: "\e910";
//}
//.icon-tablet-mini:before {
//  content: "\e905";
//}
//.icon-desktop-comp:before {
//  content: "\e906";
//}
//.icon-mobile-phone:before {
//  content: "\e907";
//}
//.icon-down-arrow:before {
//  content: "\e908";
//}
//.icon-up-arrow:before {
//  content: "\e909";
//}

//.icon-equal-sign:before {
//  content: "\e90c";
//}