@keyframes show-modal {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes hide-modal {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

.tfg-modal {

    pointer-events:none;
    z-index:-20;

    opacity: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%,-50%,0);

    width: 60vw;
    max-height:90vh;
    border-radius: 16px;

    box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
    background-color: white;
    transition: width 100ms ease;
    transition-property: width, height;

    .tfg-modal-message {

        display:flex;
        font-size: 14px;
        text-align:center;
        display: flex;
        justify-content: center;

        &-content {
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 48px;
            opacity: 1;
            display: grid;
            grid-gap: 10px;
            font-size:16px;
            transition: opacity 100ms ease-out;
            iframe {
                border: 0;
                width: 100%;
                height: 500px;

            }
        }
    }

    .tfg-modal-title {

        position: relative;
        padding: 16px;
        background-color: mdgv( $colors, 'hex', 'main' );
        transition: background-color 100ms ease-out;
        transition-property: background-color,color;
        .close-button {
            display:flex;
        }
        .escape-key {
            font-size: 12px;
            padding-right:8px;
            align-self: end;
        }

        h3 {
            color: white;
            text-transform: capitalize;
            font-size: 24px;
            font-weight: normal;
            padding-right: 42px;
            margin-bottom: 0;
            text-align: center;
            line-height: 1;
        }

        button {
            position: absolute;
            background: transparent;
            border: 0;
            outline: 0;
            top: 50%;
            right: 16px;
            left: auto;
            color: white;
            transform:translate3d(0,-50%,0);
            font-size:24px;
            cursor: pointer;
        }

        &-content {
            transition: opacity 100ms ease-out;
        }

    }

}

.tfg-modal.success {

    .tfg-modal-message {
        &-content {
            transition-timing-function: ease-in;
        }
    }

    .tfg-modal-title {
        color: white;
        background-color: var(--success);
        transition-timing-function: ease-in;
        &-content {
            transition-timing-function: ease-in;
        }
    }
}

.tfg-modal.error {

    .tfg-modal-message {
        &-content {
            transition-timing-function: ease-in;
        }
    }

    .tfg-modal-title {
        color: white;
        background-color: var(--danger);
        transition-timing-function: ease-in;
        &-content {
            transition-timing-function: ease-in;

        }
    }

}

.tfg-modal.hide, .tfg-modal.show {
    animation-duration: 100ms;
    animation-delay: 0s;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

.tfg-modal.hide {
    pointer-events: none;
    animation-name: hide-modal;
    animation-timing-function: ease-out;
    z-index:1000;
}

.tfg-modal.show {
    opacity:1;
    pointer-events: auto;
    animation-name: show-modal;
    animation-timing-function: ease-in;
    z-index:1000;
}

@media only screen and ( max-width: 1321px ) {

    .tfg-modal {
        width: 90vw;
    }
    .tfg-modal-message-content {
        padding:12px;
    }
}
