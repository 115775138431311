// component ///////////////////////////////////

.top-fan-growth {

    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-around;
    margin: 10px;
    padding: 16px;
    background: white;
    text-align: center;

    &.loading {
        background-color: #e9f3fb;
        min-height: 225px;
    }

    .fan-growth-icon {
        width: 75%;
        height: auto;
        max-width: 150px;
    }

}

.top-fan-growth-header {
    font-size: 26px;
    letter-spacing: 0.5px;
}

.top-fan-growth-content {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 16px;
}

.top-fan-growth-content-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;

    &.total,
    &.indicator {
        font-size: 52px;
        line-height:1.25;
        font-weight: 600;
        color: mdgv( $colors, 'hex', 'secondary' );

        > span {
            font-size: 14px;
            font-weight: 300;
        }

    }

}

.top-fan-growth-content-box-indication {
    display: flex;
    flex-direction: row;
    transform:translateX(-5%);

    &-indicator {
        font-size: 75px;
        font-weight: 200;
        line-height: 0.75;
    }

    &-indicator,
    &-value {
        background: linear-gradient(
            to bottom right,
            #{ mdgv( $colors, 'hex', 'secondary' ) } 0%,
            #{ mdgv( $colors, 'hex', 'main' ) } 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@media only screen and (max-width: 600px) {
    .top-fan-growth-header {
        margin-bottom: 16px;
    }
    .top-fan-growth-content {
        grid-template-columns: auto;
    }
}

// icon ////////////////////////////////////////
.fan-growth-icon-plus > line,
.fan-growth-icon-bust > path,
.fan-growth-icon-ellipse {
    fill: none;
    stroke: mdgv( $colors, 'hex', 'main' );
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-miterlimit: 10;
}

.fan-growth-icon-ellipse {
    fill: url('#fan-growth-icon-gradient');
    stroke-width: 2;
}

