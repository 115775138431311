#makes-metrics {
  height: 100%;
  overflow-y: auto;

  // fixing strange bug,
  // when you click the end date range it breaks one of the date range css styles
  .DateInput_1:nth-child(4) {
    margin-right: 35px;
  }

  #dash-header {
    position: static !important;
  }
  
  // FORM

  form {
    width: 50%;
    margin-top: 50px;

    h4 {
      font-weight: 600;
      margin: 30px 0 20px 40px;
      border-bottom: solid 1px #dcdcdc;
    }

    h4:nth-of-type(2), h4:nth-of-type(3) {
      margin-top: 25px;
    }

    .form-check {
      margin: auto 0 auto 40px;
      width: 175px;
    }

    .form-check-label:hover, input:hover {
      cursor: pointer;
      font-weight: 600;
    }

    .form-check-label.active {
      font-weight: 600;
    }

    .form-check-label.disabled:hover {
      font-weight: 400;
    }
  }


  // SUBMIT BUTTONS

  #submit-button {
    width: 160px;
    background: #53b0c9;
    color: #fff;
    margin: 50px 0 0 40px;
  }

  #submit-button:hover {
    background: #2559b4;
    transition: background .3s ease-in-out;
  }

  #submit-button.disabled:hover {
    background: #53b0c9;
  }

  #clear-form-button {
    color: #2559b4;
    padding: 5px;
    margin-left: 25px;
  }

  #clear-form-button:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #52b1ca;
  }

  .loading-wrapper {
    height: 32px;
  }


  // RESULTS

  #makes-metrics-results {
    overflow-x: hidden;
    padding: 40px;
    margin-top: 40px;

    h4 {
      margin: 20px 0;
    }

    h4:nth-of-type(2) {
      margin-top: 50px;
    }

    .makes-metrics-graph {
      height: 500px;
      margin-top: 30px;
    }

    // TABLE

    table {
      margin-top: 80px;

      .dash-table-title {
        background-color: #0d2832;
      }

      .dash-table-header {
        background-color: #52b1ca;
      }

      td, th {
        text-align: right;
        min-width: 125px;
      }

      td {
        padding-right: 18px;
      }

      td:first-of-type, th:first-of-type {
        text-align: center;
      }

      td:nth-of-type(2), th:nth-of-type(2) {
        text-align: left;
      }
    }
  }


  #date-pickers {
    padding: 9px;
    position: relative;
    bottom: 5px;
    margin-left: 20px;

    .period-text {
      font-size: 12px;
      letter-spacing: 0.5px;
      color: white;
      padding-left: 7px;
    }

    .date-picker {
      .icon-calendar-date-picker {
        position: relative;
        right: -189px;
        top: -29px;
        font-size: 18px;
        color: #6d7177;
      }

      .DateRangePicker {
        height: 37px;

        .DateRangePickerInput {
          height: 36px;
          border-radius: 0;
          border: none;
          border-bottom: 1px solid rgb(220, 220, 220);

          .DateInput_1 {
            width: 81px;

            .DateInput_input_1 {
              height: 31px;
              cursor: pointer;
              font-weight: 400;
              font-size: 12px;
              letter-spacing: 1.3px;
              border: none;
              padding: 0;
              text-align: center;
              position: relative;
              bottom: 1px;
              margin-top: 3px;
            }

            .DateInput_input__focused {
              background-color: #b8e5fc;
              border-radius: 3px;
            }
          }

          .DateRangePickerInput_arrow + .DateInput_1 {
            margin-right: 35px;
          }

          .DateInput_1:first-of-type {
            margin-left: 10px;
          }

          .DateRangePickerInput_arrow {
            position: relative;
            bottom: 1px;
            margin: 0 3px;
          }
        }

        div {
          max-height: 100%;
        }

        .CalendarMonth_caption {
          padding-bottom: 42px;
        }

        .DayPickerNavigation_button {
          max-height: initial;
        }

        .DayPicker_weekHeader_li {

          small {
            color: #757575;
            font-size: 10px;
          }
        }

        .CalendarMonth_table {
          .CalendarDay {
            font-size: 12px;
          }

          .CalendarDay:focus {
            outline: none;
          }
        }

        // LEGEND

        .legend {
          padding: 10px 0;

          .legend-text {
            font-size: 12px;
            color: rgb(72, 72, 72);
            margin: 0 15px;
          }

          .legend-color-block {
            width: 10px;
            height: 10px;
            display: inline-block;
          }

          .legend-color-block.light {
            background-color: rgb(157, 209, 248);
          }

          .legend-color-block.dark {
            background-color: rgb(111, 177, 226);
          }
        }
      }
    }

    // DAY COLORS FOR CURRENT PERIOD
    .date-picker.current {
      margin-right: 10px;

      .CalendarMonth_table {

        .CalendarDay__selected_start, .CalendarDay__selected_span, .CalendarDay__selected_end {
          background: #9DD1F8;
          border: 1px double #319EF0;
        }

        .CalendarDay__selected_span {
          border: 1px double #319EF0;
        }

        .CalendarDay__selected_start, .CalendarDay__selected_end {
          background: #319EF0;
        }

        .CalendarDay__highlighted_calendar {
          background: #6FB1E2;
          color: #fff;
          border: 1px double #fff;
        }
      }
    }

    // DAY COLORS FOR COMPARISON PERIOD
    .date-picker.comparison {
      .DateRangePicker_picker_1 {
        left: -186px !important;

        .CalendarMonth_table {
          border-collapse: collapse;
          border-spacing: 0;

          .CalendarDay__selected_start, .CalendarDay__selected_span, .CalendarDay__selected_end {
            border: 1px double #2373B0;
            color: #fff;
          }

          .CalendarDay__selected_span {
            background: #6FB1E2;
          }

          .CalendarDay__selected_start, .CalendarDay__selected_end {
            background: #2373B0;
          }

          .CalendarDay__highlighted_calendar {
            background: #9DD1F8;
            color: #fff;
            border: 1px double #fff;
          }
        }
      }
    }
  }
}