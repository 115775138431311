.campaign-breakdown {

    position: relative;
    margin: 10px;

    background: #FFFFFF;
    width: 100%;
    overflow: hidden;

    &.loading {

        background-color: #e9f3fb;
        max-width: 1180px;

        > div {
            min-height: 250px;
        }
    }

    .dash-table .dash-table-header td {
        color: white;
        font-weight: lighter;
    }

    .campaign-breakdown-hide-show-button {
        background-color: transparent;
        outline: none;
        border: 0;
        font-size: 24px;
        cursor: pointer;
        color: mdgv( $colors,'hex','main');
    }

    span {

        &.spacer {
            display:inline-block;
            margin-right:2px;
            margin-left: 2px;
        }

        &.up {
            color: green;
            &.cpc,
            &.bounce-rate {
                color: darkred;
            }
        }

        &.down {
            color: darkred;
            &.cpc,
            &.bounce-rate {
                color: green;
            }
        }

    }

    tbody td {
        font-size: 12px;
    }

    .hidden-row {

        td {
            padding: 0;
            border-bottom-width: 0;
            height: 0;
        }

        .multiple-creative-overflow-div {
        }

        .overflow-div {

            overflow: hidden;
            display: grid;
            justify-content: center;
            grid-gap: 0px;
            margin: 0;
            transition: margin 300ms ease-in;
            transition-property: margin, height;

            .content-div {

                background: white;
                border:1px solid rgb(200,200,200);
                display: grid;
                grid-template-columns: 1fr;

                opacity: 0;
                height: 0;
                padding: 0;
                border-bottom: 0;
                transform: translateY( -100% );
                transition: transform 200ms ease-in;
                transition-property: opacity, border-bottom, transform, height, padding;

                .ad-wrapper {
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    min-height: 172px;

                    .ad-wrapper-ad {
                        display: grid;
                        align-items: center;
                        margin: 0 auto;
                        padding-left: 3px;

                        &:nth-of-type(2) {
                            padding-right: 3px;
                            padding-left: 0;
                        }

                        &:hover {
                            .overlay {
                                opacity: 1 !important;
                            }

                            .ad-wrapper-ad-cta {
                                background: linear-gradient(to bottom, rgba(0, 92, 182, 0.5) 0, rgba(0, 92, 182, 1) 100% ) !important;
                            }
                        }

                        .ad-wrapper-ad-image {

                            align-self: stretch;
                            display: flex;
                            align-items: center;
                            width: 139px;
                            height: 138px;
                            position: relative;

                            .overlay {
                                opacity: 0;
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                width: 100%;
                                background-color: rgba(255,255,255,0.8);
                                height: 72px;

                                .ad-wrapper-ad-model {
                                    font-weight: bold;
                                    color: rgb(77, 77, 77);
                                    padding-bottom: 4px;
                                }

                                .ad-wrapper-ad-price {
                                    font-size: 23px;
                                    color: #0078f0;
                                    white-space: nowrap;
                                    font-weight: 400;
                                }
                            }

                            img {
                                background-size: contain;
                                background-position: center center;
                                background-repeat: no-repeat;
                                height: 100%;
                                width: 100%;
                            }
                        }

                        .ad-wrapper-ad-cta {
                            position:relative;
                            display: flex;
                            justify-content: center;
                            align-self: end;
                            height: 26px;
                            width: 108px;
                            background: linear-gradient(to bottom, rgba(1, 120, 240, 0.5) 0, rgba(1, 120, 240, 1) 100% );
                            font-size: 14px;
                            color: rgb(255, 255, 255);
                            text-transform: capitalize;
                            margin: 0 auto 4px;
                            border: 1px solid #8eb4de;

                            span {
                                align-self: center;
                            }
                        }
                    }
                }

                .logo-wrapper {

                    background-color: rgb(230, 230, 230);
                    height: 77px;

                    .ad-wrapper-arrow {
                        display: flex;
                        width: 25px;
                        height: 25px;
                        margin-bottom: 15px;
                        align-items: center;
                        align-self: center;
                        font-size: 48px;
                        transform: translateY(25%);
                        &.left {}
                        &.right {}

                        svg {
                            color: #dcdcdc;
                            border-radius: 50%;
                            width: 100%;
                            height: 100%;
                            background-color: #b8b8b8;
                        }

                        svg:hover {
                            color: white;
                        }
                    }

                    img {
                        max-height: 60px;
                        max-width: 180px;
                        width: auto;
                        height: auto;
                    }
                }
            }
        }

        &.show {

            background-color: rgb(242,245,248);

            .overflow-div {
                grid-gap: 10px;
                margin: 20px;
                transition-timing-function: ease-out;
            }

            .content-div {
                opacity: 1;
                box-sizing: content-box;
                height: 250px;
                width: 300px;
                padding: 16px;
                border-bottom: 1px solid #dadada;
                transform: translateY( 0% );
                transition-timing-function: ease-out;
            }

            .multiple-creative-overflow-div {

                display: flex;
                max-width: 100%;

                #multiple-creative {
                    width: 100%;

                    .content-div {
                        height: inherit;
                        width: inherit;
                        border: none;
                        border-radius: 20px;
                        box-shadow: 1px 2px 4px 0 rgba(10,58,103,0.15);
                    }

                    #tall-ads, #square-ads {
                        min-width: 44.75%;
                        margin: 15px;
                        height: 625px;
                    }

                    #wide-ads {
                        min-height: 90px;
                        margin: 15px;
                        padding: 25px 0;
                        width: 100%;

                        a {
                            margin-bottom: 20px;
                        }

                        a:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}

.campaign-breakdown.social-paid-campaign-breakdown,
.campaign-breakdown.social-organic-campaign-breakdown {

    .dash-table tbody tr {

        background: rgba(100,100,100,0.05);

        &:nth-child(4n+1) {
            background: white;
        }

        > td:nth-last-child(1) {
            width: 100px;
        }

    }

    .overflow-div {
        display: grid;
        grid-template: 1fr / 1fr;
        background-color: #eee;
        padding:0;
        transition: padding 200ms ease;
    }

    .sub-tables {

        height:0;
        opacity:0;
        display:grid;
        grid-gap:20px;
        transition: opacity 400ms ease;

        .banner-dash {
            padding:8px;
            background-color: #666;
            text-transform: none;
            font-size:15px;
            font-weight: lighter;
            margin:0;
        }
    }

    .sub-table {

        background-color: white;
        border: 1px solid rgba(0,0,0,0.3);

        .sub-table-banner {
            background-color: #666;
            color: white;
            padding: 8px;
            font-weight: lighter;
            font-size: 14px;
        }

        .sub-table-td {
            display:grid;
        }

        .sub-table-header {
            color: white;
            background-color: mdgv( $colors, 'hex', 'main' );
            display: grid;
        }

        .sub-table-td, .sub-table-header {
            > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 8px;
                > span:not(:nth-last-child(1)) {
                    &:after {
                        content:','; // \00a0'
                        align-self: flex-end;
                    }
                }
            }
        }

        &.analytics {

            .sub-table-td, .sub-table-header {
                > div {
                    flex-direction: row;
                    > span:not(:nth-last-child(1)) {
                        &:after {
                            content:'';
                        }
                    }
                }
            }

        }

        &.targeting {

            .sub-table-td {

                > div {

                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    flex-wrap: wrap;
                    align-content: center;
                    perspective: 1000px;
                    perspective-origin: center;

                    > span {

                        font-size: 10px;
                        display: inline-block;
                        margin: 2px;
                        padding: 8px;
                        background: rgba(0,0,0,0.1);
                        border-radius: 8px;
                        transition: background 100ms ease;

                        &:hover {
                            background: rgba(0,0,0,0.15);
                        }

                        &:after {
                            content: '';
                        }

                    }

                }

            }

        }
    }

    $ad-creative-spacing: 20px;

    .ad-creative-container {

        display: grid;
        grid-template: 1fr / repeat( 2, 1fr );
        max-width: 100%;
        overflow:hidden;
        grid-gap: $ad-creative-spacing;
        min-height: 0;
        min-width: 0;
        height:0;

    }

    .ad-creative-container-organic {

        width: 50%;
        overflow:hidden;
        min-height: 0;
        min-width: 0;
        height:0;
        margin:auto;

    }

    .ad-creative-campaign-name {
        display:none;
    }

    .ad-creative-wrapper {

        position:relative;
        overflow: hidden;
        min-height: 100px;
        padding: $ad-creative-spacing;
        border-radius: $ad-creative-spacing / 3;
        box-shadow: 2px 2px 8px rgba( 0, 0, 0, 0.2 );
        background-color: white;

    }

    .ad-creative-content-crop {

        // use the padding trick to create aspect ratio box
        position: relative;
        overflow: hidden;
        border: 0;

        > img {
            border: 1px solid rgba(0,0,0,0.1);
            border-radius: 4px;
            overflow: hidden;
            width:100%;
        }

    }

    .ad-creative-social-stat {

        display: flex;
        flex-direction: row;
        align-items:center;
        color: mdgv( $colors, 'hex', 'tertiary' );

        > * {
            margin: 2px;
            display: inline-block;
        }

        &.video-plays {
            flex-direction: column;
            > *:nth-child(1) {
                font-weight: bolder;
                line-height:1;
            }
        }

    }

    .show {

        .overflow-div {
            padding: $ad-creative-spacing*2 $ad-creative-spacing;
            grid-gap: calc( #{$ad-creative-spacing} * 2 );
            margin:0;
        }

        .ad-creative-container {
            height:auto;
            overflow:visible;
        }

        .ad-creative-container-organic {
            height:auto;
            overflow:visible;
        }

        .sub-tables {
            height: auto;
            opacity: 1;
        }
    }

    @media only screen and ( max-width: 1200px ) {

        .ad-creative-content-crop {
            margin-bottom: 120px;
        }

        .ad-creative-stats {
            flex-direction: column;
            height: auto;
        }

        .ad-creative-social-stat,
        .ad-creative-social-stat.video-plays {
            flex-direction: row;
        }

    }

}

.campaign-breakdown.social-organic-website-metrics {
    min-height: 153px;
}

.campaign-breakdown.social-paid-pla-breakdown.social-paid-campaign-breakdown {

    tbody > tr > td:not(:nth-last-child(1) ) {

        transform:translateX(50px);
        font-size: 14px;
        font-weight: normal;

    }

    .ad-creative-content-crop {
        margin-bottom:0;
        border-bottom:0;
    }

    .sub-table {

        .sub-table-header,
        .sub-table-td {
            span {
                padding: 10px;
            }
        }

        .sub-table-td {
            border-bottom: 1px solid #DADADA;

            &:nth-child(odd) {
                background-color: rgba(100,100,100,.1);
            }
        }

    }

}

.app-container:not(.puppeteer-browser) .campaign-breakdown.social-paid-pla-breakdown.social-paid-campaign-breakdown {

    .ad-creative-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .ad-creative-wrapper {
        width: 50%;
    }

}