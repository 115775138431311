#admin-sidebar {

  background-color: #0d2832;
  padding: 0;
  min-height: calc( 100vh - 64px );

  .side-nav {
    ul {
      list-style: none;
      font-size: 18px;
      font-weight: 500;
      padding: 0;

      li {
        height: 64px;

        a {
          padding: 18px 45px 18px 24px;
          font-weight: 400;
          display: block;
          color: #fff;
          text-decoration: none;
          transition: all .2s ease-in-out;

          @include sm-desktop {
            padding: 18px 0;
            text-align: center;
          }
        }
      }

      li:hover {
        a {
          transition: all .2s ease-in-out;
          color: #52b1ca;
        }
      }

      li.active {
        background-color: rgb(37, 61, 70);
      }
    }
  }

  #desktop-side-nav {

    @include mobile {
      display: none;
    }
  }

  .side-nav-menu-button {
    color: #fff;
    padding: 10px;
    display: none;
    position: relative;
    z-index: 4;

    @include mobile {
      display: block;
    }
  }

  #mobile-side-nav {
    display: none;
    background-color: #0d2832;
    position: fixed;
    top: 64px;
    z-index: 3;
    height: 100%;
    width: 16.666667%;
    transition: width 0.25s;

    @include mobile {
      display: block;
    }

    ul {
      margin-top: 44px;
    }
  }

  #mobile-side-nav.open {
    width: 50%;
    transition: width 0.25s;
  }

  #gray-screen {
    display: none;
    position: fixed;
    background-color: rgba(0, 0, 0, .4);
    transition: background-color .25s;
    width: 100%;
    height: calc(100vh - 64px);
    top: 64px;
    z-index: 2;

    @include mobile {
      display: block;
    }
  }

  #gray-screen.closed {
    background-color: transparent;
    transition: background-color .25s;
    visibility: hidden;
  }
}