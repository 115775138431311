#monthly {

  display:grid;
  grid-gap:40px;
  position:relative;
  background: #FFFFFF;
  width: calc( 100% - 20px );
  overflow: hidden;

  margin: 10px;
  padding: 40px 20px;

  .monthly-header {

    color: rgba(5, 32, 38, 0.9);
    text-align: center;
    font-size: 26px;

  }


  .monthly-line-chart-area {

    position: relative;
    perspective: 1000px;
    perspective-origin: center;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
    margin-right: 0;


    .monthly-weekly-line-chart-data {

      position:absolute;

      display: grid;
      justify-items: center;
      align-items: center;

      top: 3px;

      padding-top: 1%;
      font-size: 0.9vw;
      border-top: 2px solid white;

      width: calc( 100% - 76.666px);
      margin-left: 38.333px;
      margin-right: 38.333px;

      .monthly-weekly-info {

        text-align: center;
        margin:2px;
        width: calc( 100% - 4px);

        &.max {
          color: white;
          font-weight: bold;
        }


        .monthly-weekly-columns {

          display: grid;
          grid-template-columns: 1fr 1fr;
          padding: 8px;

        }
      }
    }

  } // .monthly-line-chart-area


  .dash-table {

    width: 90%;
    float: none;
    margin: 0 auto; // havn't had to use this hack in a while!

    th {
      width: 33.3333%;
    }

    tr.best {
      background-color: rgba(200,200,200,0.5);
    }


  }

  .line-chart-button {

    display:flex;
    background: transparent;
    outline: none;
    border: 0;
    position:absolute;
    top:43%;
    font-size:12px;
    cursor: pointer;
    border-radius: 10px;
    padding:8px;
    transition: background 100ms ease;

    &:hover {
      background: rgba(200,200,200,0.5);
      transition-timing-function: ease-in;
    }

    &-metric-one {
      left: 1vw;
      transform: translateY(-50%) translateX(-33%) rotate(-90deg);
      .line-chart-button-color {
        background-color: mdgv( $colors, 'hex', 'main' );
      }
    }

    &-metric-two {
      right: 1vw;
      transform: translateY(-50%) translateX(33%) rotate(-90deg);
      .line-chart-button-color {
        background-color: mdgv( $colors, 'hex', 'tertiary' );
      }
    }

    span {
      line-height: 1vw;
    }

    .line-chart-button-color {
      border-radius: 4px;
      width: 1vw;
      height: 1vw;
      margin-right: 0.5vw;
    }

  }

  @media only screen and ( max-width: 700px ) {
    .line-chart-button {
      top:33.33%;
      font-size:0.9vw;
    }
  }

  &.same-y-scale {

    .monthly-weekly-line-chart-data {
      width: calc( 100% - 45.333px);
      margin-left: 32.333px;
      margin-right: 13px;
    }

  } // &.same-y-scale

  .monthly-under-stats {

    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media only screen and ( max-width: 588px ) {
      flex-direction: column;
    }

    > div {
      padding: 20px;
    }

    .title {
      font-size:18px;
      font-weight:bold;
      line-height: 30px;
    }

    .metric {
      font-size: 30px;
      font-weight: lighter;
    }

    .metric-one {
      color: mdgv( $colors, 'hex', 'tertiary' );
    }

    .stats {

      color: mdgv( $colors, 'hex', 'main' );

      &.up {
        color: rgba(0, 170, 85, 1);
        span {
          color: darken(rgba(0, 170, 85, 0.9), 10% );
        }
      }

      &.down {
        color: rgba(170, 17, 17, 1);
        span {
          color: darken( rgba(170, 17, 17, 0.9), 10% );
        }
      }

      font-size: 18px;
      display:grid;
      grid-gap:5px;
      grid-template: 1fr / 3fr 1fr 3fr;

    }

    .metric-two {
      color: mdgv( $colors, 'hex', 'main' );
    }

  }

}

#monthly.loading {
  background-color: #e9f3fb;

  .loading-wrapper {
    min-height: 650px;
  }
}