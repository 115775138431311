#targeting {

    position: relative;
    margin: 10px;
    background: #FFFFFF;
    height: 380px;
    width: 100%;
    overflow: hidden;

}

*:fullscreen {
    .map-modal-button {
        display: none;
    }
}

#targeting.loading {

    background-color: #e9f3fb;

}

#targeting-map,
#targeting-extra {

    position:absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

}

.ui-switch {
    width:44px;
    height:39px;
    overflow:hidden;
    cursor: pointer;
    font-size: 16px;
    padding: 12px;
    position: absolute;
    top: 11px;
    left: 197px;
    pointer-events: auto;
    z-index: 1;
    outline: none;
    background: white;
    margin: 0;
    line-height: 1;
    border: 1px solid rgba(0,0,0,0.3);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.show-targeting-extra {
    opacity:1;
}

#targeting-extra {

    opacity:1;
    transition: opacity 200ms ease-out;

    perspective: 1000px;
    perspective-origin: center;

    &.off {
        pointer-events:none;
        opacity:0;
        transition-duration: 0s;
        transition-timing-function: ease-in;
    }

    z-index: 1;
    pointer-events: none;

    .showing {

        position:absolute;
        bottom: 24px;
        right: 64px;
        font-size:12px;
        font-weight:300;
        color:black;
        background: transparentize(#f5f5f2, .3);
        padding:6px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius:3px;

        .showing {
            &-text {
                padding-right:4px;
                &:after {
                    content: ':';
                }
            }
            &-amt {

            }
            &-seperator{
                padding-right:4px;
                padding-left:4px;
            }
            &-total {

            }
        }
    }
    .map-modal-button {
        overflow:hidden;
        cursor: pointer;
        font-size: 16px;
        padding: 11.2px;
        position: absolute;
        top: 64px;
        right: 9px;
        pointer-events: auto;
        z-index: 1;
        border: 1px solid rgba(0,0,0,0.3);
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        outline: none;
        background: white;
        margin: 0;
        line-height: 1;
    }

    .reset-map-center-button {
        overflow:hidden;
        cursor: pointer;
        font-size: 16px;
        padding: 11.2px;
        position: absolute;
        top: 11px;
        right: 68px;
        pointer-events: auto;
        z-index: 1;
        border: 1px solid rgba(0,0,0,0.3);
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        outline: none;
        background: white;
        margin: 0;
        line-height: 1;
    }

    .pointer {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: auto;
        width: 25%;
        min-width: 250px;
        max-width: 400px;
        background: transparent;

        .reset-input-button {
            overflow:hidden;
            cursor: pointer;
            pointer-events: auto;
            z-index: 1;
            border: 1px solid rgba(0,0,0,0.3);
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
            outline: none;
            background: white;
            margin: 0;
            position:absolute;
            right: -32px;
            font-size:12px;
            line-height:1;
            padding:4px;
            top:50%;
            transform: translateY(-56%);
            color: dimgray;
            transition: color 100ms ease-out;
            &:hover, &:active, &:focus {
                color: black;
                transition-timing-function: ease-in;
            }
        }

        label {
            display:flex;
            align-items:center;
            border: 1px solid rgba(0,0,0,0.1);
            margin-bottom: 0;
            width: 100%;
            background: transparent;

            .search-icon {
                padding-top: 12px;
                padding-right:10px;
                padding-bottom: 10px;
                padding-left:10px;
                align-self:stretch;
                background: rgba(250,250,250,0.95);
                border-right:1px solid rgba(0,0,0,0.1);
            }
        }

        input {

            padding: 6px;
            padding-left:10px;

            border: 0;
            outline: none;
            font-size: 14px;
            line-height: 26px;
            width:100%;
            background: rgba(250,250,250,0.6);
            color: rgba(0,0,0,0.8);

            transition: background 100ms ease;
            transition-property: color, background;

            &::placeholder {
                color: rgba(0,0,0,0);
                font-size: 10px;
                line-height: 26px;
            }

            &:focus, &:active {
                background: rgba(250,250,250,1);
                color: rgba(0,0,0,1);

                &::placeholder {
                    color: rgba(0,0,0,0.3);
                }

            }



        }
    }

    button {
        cursor: pointer;
    }

    .flip-campaign-box {

        opacity:1;
        width:44px;
        height:39px;
        overflow:hidden;
        cursor: pointer;
        font-size: 16px;
        padding: 12px;
        position: absolute;
        top: 0;
        left: calc( 169px + 16px );
        pointer-events: auto;
        z-index: 1;
        border: 1px solid rgba(0,0,0,0.3);
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        outline: none;
        background: white;
        margin: 0;
        line-height: 1;
        transition: opacity 400ms ease-out;
        &.off {
            pointer-events:none;
            opacity:0;
            transition-duration: 0s;
            transition-timing-function: ease-in;
        }
    }

    .campaigns {

        position: absolute;

        left: 11px;
        bottom: 20px;
        height: calc( 100% - 90px);
        width: 169px;
        background: transparent;
        pointer-events: auto;
        transition: height 100ms ease-out;

        @keyframes flip-it {
            0% {
                transform: translateX(0px) translateZ(0);
            }
            50% {
                transform: translateX(15px) translateZ(20px);
            }
            100% {
                transform: translateX(0px) translateZ(0);
            }
        }

        &.flip-animation {
            animation-name: flip-it;
            animation-duration: 400ms;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;
        };

        .campaign-box {
            position:absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            transition: transform 400ms ease;
            -webkit-backface-visibility: hidden;
            background: white;
            border: 1px solid rgba(0,0,0,0.3);
            box-shadow: 2px 2px 10px rgba(0,0,0,0.1);
            overflow-x: hidden;
            overflow-y: hidden;

            &-campaigns {
                z-index: 1;
                transform: rotateY(0deg);
                pointer-events:auto;
            }
            &-areas {
                z-index: 2;
                transform: rotateY(180deg);
                pointer-events:none;
                .area-num {
                    color: gray;
                }
            }

        }

        &.flip {
            .campaign-box {
                &-campaigns {
                    transform: rotateY(180deg);
                    pointer-events:none;
                    -webkit-backface-visibility: hidden;
                }
                &-areas {
                    transform: rotateY(0deg);
                    pointer-events:auto;
                    -webkit-backface-visibility: hidden;
                }
            }
        }

        .collapse-button {
            position:relative;
            background:transparent;
            border: 0;
            outline:none;
            margin: 0;
            text-align: left;

            padding: 10px;
            padding-bottom:15px;

            font-size: 18px;
            font-weight: bold;
            width: 100%;
            line-height: 1;
            z-index:1;
            box-shadow: 5px 15px 15px rgba(0,0,0,0.1);

        }

        .campaign-box-ul {
            height: calc( 100% - 44px );
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }

        ul {
            margin: 0;
            padding: 0;
        }

        &.iscollapsed {
            height: 40px;
        }

        .campaign-list-item-button {

            position:relative;

            width: 100%;
            text-align: left;
            line-height: 1.3;
            background: transparent;
            cursor: pointer;
            font-size: 12px;
            outline:none;
            border:0;
            padding: 12px;
            transition: background-color 100ms ease-out;
            text-transform: capitalize;

            &:after {
                content: '';
                position: absolute;
                bottom: 1px;
                left: 0;
                width: 100%;
                height: 1px;
                background: orangered;
                opacity: 0;
                transform: translate3d( -100%, 0, 0 );
                transition: transform 100ms ease-out;
                transition-property: opacity, transform;
            }

            &.warning {

                background-color: rgba( orangered, 0.1 );
                overflow: hidden;

                &:after {
                    opacity: 1;
                    transform: translate3d( 0, 0, 0 );
                    transition-timing-function: ease-in;
                }

            }

            &:hover {
                background-color: rgba(0,0,0,0.1);
                transition-timing-function: ease-in;
            }

            &.disabled {
                pointer-events: none!important;
                color: lightgrey;
                span.area-num {
                    color: lightgrey;
                }
            }

            svg {
                margin-right:8px;
                font-size:14px;
                line-height:1;
            }

        }

        li:nth-child( even ) button {
            background-color: rgba(0,0,0,0.02);
        }

    }

}

.info-window {
    width: 250px;
}

.info-window-title {

    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: blue;
    color: white;
    margin: 4px;
    padding: 8px;
    font-weight: 400;
    text-align: center;
    text-transform: capitalize;
    line-height: 1.5;

}

.info-window-paragraph {
    float:none;
    margin: 0 auto;
    margin-top: 12px;
    padding: 12px;
    width: 200px;
    line-height: 1.5;
    text-align: center;

}


// hacky way to remove the close button on infowindows
.gm-style-iw + button {display: none;}
