#top-vehicles {

  position: relative;
  margin: 10px 10px 0;
  z-index: 1;
  padding-bottom: 10px;
  min-height: 425px;
  background-color: #FFFFFF;

  .dash-table {

    th:first-of-type {
      min-width: 300px;
    }

    th {
      width: calc((100% - 300px) / 6);
    }
  }
}

#top-vehicles.loading {
  background-color: #e9f3fb;

  .loading-wrapper {
    min-height: 425px;
  }
}