.trends-chart {

    position: relative;
    margin: 10px;
    background: #FFFFFF;
    width: 100%;
    min-height: 586px;
    overflow: hidden;
    padding-bottom: 42px;

    &.loading {
        background-color: #e9f3fb;
    }

}


.trends-chart-header {

    position:relative;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 26px;
    text-align: center;
    margin-top: 16px;
    height: 76px;
    letter-spacing: 0.5px;

}

.trends-chart-area {
    position: relative;
    width: 95%;
    transform: translateX( 2.5% );
}

.trends-chart-area-info {
    display: grid;
    position: absolute;
    z-index:1;
    top: 0;
    left: 0;
    width: 100%;

    padding-left: 35px;

    text-align: center;

    background: linear-gradient(
        to bottom,
        white,
        rgba( 250, 250, 250, 0 )
    );
    &:after {
        content: '';
        position: absolute;
        top:0;
        left:0;
        width: 35px;
        height: 200%;
        background: linear-gradient(
            to bottom,
            white 50%,
            rgba( 250, 250, 250, 0 )
        );
    }
}

.trends-chart-header-label {

    position: absolute;
    right: calc( 2.5% + 16px );
    top: 50%;
    font-size: 11px;
    line-height: 0.9;
    transform: translateY(-50%);

    span {

        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: red;
        margin-right: 4px;
        display: inline-block;

    }

}

.trends-chart-area-chart {
    position: relative;
    width: 100%;
}

.trends-chart-area-info-title {
    text-transform: uppercase;
}
.trends-chart-area-info-title {
    margin-top:8px;
}
.trends-chart-area-info-total-title,
.trends-chart-area-info-total-number {
    font-size: 12px;
}

@media only screen and ( max-width: 800px )  {
    .trends-chart-area-info-title,
    .trends-chart-area-info-total-title,
    .trends-chart-area-info-total-number {
        font-size: 8px;
    }
    .trends-chart-header {
        display: grid;
    }
    .trends-chart-header-label {
        position: static;
    }
}

@media only screen and ( max-width: 530px )  {
    .trends-chart-area-info-title,
    .trends-chart-area-info-total-title,
    .trends-chart-area-info-total-number {
        font-size: 4px;
    }
}


