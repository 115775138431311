#top-clicked {
  height: 605.55px;
  width: 376.66px;
  margin: 10px;
  background-color: #FFFFFF;

  th, td {
    width: 50%;
  }
}

#top-clicked.loading,#top-cities.loading {
  background-color: #e9f3fb;
}

#top-cities {

  width: 100%;
  background-color: #FFFFFF;

  th, td {
    width: 50%;
  }

  td {
    height: 75px;
  }

  tbody > *:nth-child(even) {
    background: rgba(100, 100, 100, 0.05);
  }

}