.user-permissions {

  h6 {
    padding: 8px 4px;
  }

  #save-button {
    margin-left: 25px;
  }

  #save-button[disabled]:hover {
    background: #53b0c9 !important;
  }

  .user-permissions-list {
    margin-left: 22px;

    .row {
      border-bottom: 1px solid rgb(151, 151, 151);
      border-right: 1px solid rgb(151, 151, 151);
      border-left: 1px solid rgb(151, 151, 151);
      padding: 10px;

      &:first-child {
        border-top: 1px solid rgb(151, 151, 151);
      }

      .delete-perm-icon {
        font-size: 24px;
        padding: 2px;
        color: #e53644;
        cursor: pointer;

        &:hover {
          color: #af212c;
          transition: color .3s ease-in-out;
        }
      }
    }
  }
}