#users {
  height: 100%;

  #new-user-button {
    border-left: 1px solid grey;
    height: 100%;
    width: 160px;
    padding: 3px 10px 0 14px;

    a {
      color: white;
      text-decoration: none;

      span {
        padding: 10px;
      }
    }
  }

  .list-table-row:hover {
    background-color: rgba(255,255,255,.75);
  }

  .list-table .list-table-col {
      min-width: 300px;
  }

  .admin-role-icon {
    color: goldenrod;
    font-size: 38px;
    padding: 7px;
    position: relative;
    top: 1px;
    margin-left: 5px;
  }
}