select {
  position: relative;
  padding: 5px;
  height: 35px;
  background: white;
  border: 0 none;
  color: #484848;
  border-bottom: 1px solid gainsboro;
  cursor: pointer;
}

.select-label {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #6d7177;
  padding-left: 7px;
}