.post-impression {
  height: 400px;
  width: 50%;
  margin: 10px;
  background-color: #FFFFFF;

  th, td {
    width: 50%;
  }
}

.post-impression.loading {
  background-color: #e9f3fb;
}