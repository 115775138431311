#admin-header {
  // background-color: white;

  #dropdown-background #dropdown ul li::after {
    left: 62px;
  }
}

.container {
}

footer {
  height: 10%;
}

// APP

button:focus {
  outline: none;
}

#progress-bar {
  height: 5px;

  .bar {
    transform:translate3d(0,-3px,0);
    height: 3px;
  }

}

#error-message {
  color: #f8d7da;
  font-weight: bold;
}

// BUTTON STYLES

.add-button {
  margin: 10px 0 25px 15px;
  background-color: #faf2cc;
  color: #8a6d3b;
}

.client-delete, .inventory-delete {
  margin: 0 10px;
  background-color: #f8d7da;
  color:black;
}

.client-edit, .inventory-edit, .submit {
  margin: 0 10px;
  background-color: #d4edda;
  color:black;
}

.clearJobsButton, .deployButton, .retryAllJobsButton {
  margin-left: 100px;
  background-color: #f8d7da;
  color: black;
  height: 80%;
}

.facebook-select {
  width: 75%;
  display: grid;
  grid-template-columns: 3fr 1fr;
}
@media only screen and (max-width:1024px) {
  .facebook-select {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  button {
    display:flex;
    justify-self: flex-start;
    margin-left:0;
    margin-top:10px!important;
  }
}

.deployButton {
  background-color: #d4edda;
  color: black;
}

// CONTAINER
.management-main {
  display:grid;
  padding: 3%;
  grid-gap: 3%;
  grid-template: 1fr / 1fr 5fr;
}

.container {

  max-width:100%!important;
  padding:0!important;
  margin:0!important;

  // TOP NAV

  #top-nav {
    // background: white;
  }

  // SIDE NAV

  #side-nav {

    display:grid;
    align-content: flex-start;

    .sub-section {
      border:0;
      .list-text {
        text-align: center;
      }
    }

    .sub-section.active {
      background-color: rgba(63, 81, 181, .75);

      .list-text {
        color: white;
      }
    }

    .clearJobsButton {
      width: 100%;
      margin: 10px 0;
    }

    .retryAllJobsButton {
      width: 100%;
      margin: 30px 0 0;
      background-color: #d1ecf1;
    }
  }

  // CONTENT
  .side-nav-list {
    padding-top:0;
  }

  .content {

    .heading {
      margin-bottom: 75px;
    }

    // GENERIC LIST STYLES

    .active-list {
      .activation-row {
        width: 100%;

        .list-item {
          max-width: 20%;
          width: 20%;
          display: flex;
          align-items: center;

          span {
            width: 100%;
          }
        }

        .short-list-item {
          max-width: 10%;
          width: 10%;
          display: flex;
          align-items: center;
          span {
            width: 100%;
          }
        }

        .long-list-item {
          max-width: 50%;
          width: 50%;
          display: flex;
          align-items: center;
          span {
            width: 100%;
            font-size:14px;
          }
        }

        .column-header:nth-of-type(2) {
          span {
            padding-left: 30px;
          }
        }

        .list-item {
          .active-icon {
            color: #28a745;
          }
        }
      }

      .activation-row:hover {
        background-color: rgba(0, 0, 0, 0.075);
      }

      #first-row {
        .list-item {
          h3 {
            width: 100%;
            font-weight: bold;
            text-align: left;
          }
        }

        .column-header {
          h3 {
            text-align: center;
          }
        }
      }

      #first-row:hover {
        background-color: inherit;
      }
    }

    // CLIENTS VIEW

    #clients {

      .add-button {
        margin-right: 100px;
      }

      .search-icon {
        position: relative;
        top: 6px;
      }

      .client-row {
        width: 100%;
        margin: 25px 0;

        .card {
          width: 100%;
          padding: 10px 0;

          h2 {
            margin-bottom: 25px;
          }

          .value {
            position: relative;
            bottom: 3px;
            left: 10px;
            font-weight: 400;
          }

          .card-action {
            justify-content: flex-end;
          }
        }

        .expanded {
          margin: 25px 0;
        }
      }
    }

    // INVENTORY

    #activations {
      #first-row {
        .list-item:nth-child(1) {
          margin-left: 0;
        }

        .list-item:nth-child(2), .list-item:nth-child(3) {
          h3 {
            margin-left: 5px;
          }
        }

        .list-item:nth-child(3) {
          h3 {
            margin-left: 12px;
          }
        }
      }
      .list-item:nth-child(1) {
        max-width: 25px;
        margin-right: 75px;
      }

      .list-item:nth-child(3) {
        h3 {
          text-align: center
        }
      }

      .enabled-button {
        padding: 15px;
      }
    }

    #active-merma {
      .activation-row {
        .list-item {
          .check-box {
            margin-left: 20px;
            width: 25px;
          }
        }
      }
    }

    #merma-run {
      .header {
        margin-top: 20px;

        h5 {
          position: relative;
          top: 12px;
        }
      }

      .checkbox {
        margin-left: 25px;
        margin-bottom: 0;
      }

      .date-picker {
        margin-right: 25px;
      }

      .submit {
        margin: 50px 0;
      }
    }

    #derma-run {
      .header {
        margin-top: 20px;

        h5 {
          position: relative;
          top: 12px;
        }
      }

      .checkbox {
        margin-left: 25px;
        margin-bottom: 0;
      }

      .date-picker {
        margin-right: 25px;
      }

      .submit {
        margin: 50px 0;
      }
    }

    #jobs, #dash-jobs {

      .jobs-column {
        word-break: break-all;
        min-height: 50vh;
        background: rgb( 245,245,245 );
        padding: 10px;
        border-radius: 5px;
      }

      @keyframes job-animation {
        100% {
          opacity: 1;
          transform: translate3d( 0,0%, 0 );
        }
      }

      .job {

        padding: 10px;
        margin: 10px;

        opacity: 0;
        transform: translate3d( 0, -100%, 0 );

        animation-name: job-animation;
        animation-fill-mode: forwards;
        animation-duration: 300ms;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-delay: 1s;

        @for $i from 1 through 10 {
          &:nth-child( #{$i} ) {
            animation-delay: #{ 100*$i }ms;
          }
        }

        .job-delete:hover {
          color: #f8d7da;
        }

      }
    }

    #pacing, #sem-display-reporting {
      padding-top: 6px;

      .budget-recs {
        background-color: rgba(63, 81, 181, 0.25);
      }

      #pacing-rows, #derma-rows {
        .pacing-row, .derma-row {
          width: 100%;
          margin: 25px 0;

          .card {
            width: 100%;
            padding: 10px 0;

            .card-content {
              padding-bottom: 0;

              .campaign {
                margin-left: 20px;
              }

              .adset {
                margin-left: 20px;
              }
            }

            .header {
              margin-bottom: 14px;

              h2, h3 {
                margin: 0;
              }

              h3 {
                margin-left: 25px;
                color: rgba(63, 81, 181, 0.75);
                font-weight: bold;
                position: relative;
                bottom: 7px;
              }
            }

            .active-icon {
              margin-left: 15px;
            }

            .pacing-card-row, .derma-card-row {
              margin-top: 10px;

              .card-attr {
                margin: 0 15px;

                h6 {
                  margin-bottom: 0;
                  padding-top: 2px;
                  margin-right: 5px;
                }
              }

              .card-attr:first-of-type {
                margin-left: 0;
              }
            }

            .campaigns-panel {
              margin: 10px -16px 0;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              box-shadow: none;

              .summary {
                padding: 0 16px;
                display: inline-flex;

                h6 {
                  position: relative;
                  left: 74px;
                }

                .icon {
                  left: 45px;
                  top: 43%;
                }
              }

              .panel-content {
                margin: 0;
              }

              .panel-details {
                padding-left: 49px;
                padding-top: 0;

                .ad {
                  margin-bottom: 15px;

                  .card-attr {
                    margin-left: 0;
                  }

                  .cpo, .over, .under {
                    margin-left: 10px;
                    padding-top: 3px;
                  }

                  .over {
                    color: #721c24;
                  }

                  .under {
                    color: #3f51b5;
                  }

                  .cpo {
                    color: #dc3545;
                  }
                }
              }
            }

            .campaigns-panel:before {
              background-color: #ffffff;
            }

            .card-action {
              justify-content: flex-end;
            }
          }
          //
          //.expanded {
          //  margin: 25px 0;
          //}
        }
      }
    }

    #benchmarks, #seim-settings {
      .text-field {
        margin-right: 25px;
      }

      .submit {
        margin-left: 15px;
      }
    }

    #pacing-settings {
      .text-field {
        margin: 5px 0;
        min-width: 300px;
      }

      .submit {
        height: 50px;
        width: 150px;
        margin-left: 50px;
      }
    }

    #creation-inputs {
      .text-field {
        width: 25%;
        min-width: 200px;
        margin-top: 15px;
      }
    }

    #creation {

      .select-input {
        width: 200px;
      }

      .submit {
        margin-left: 138px;
      }
    }

    #seim-settings {

      .text-field {
        min-width: 200px;
      }

      #keywords {
        h4 {
          margin-top: 50px;
        }

        .keyword-row {
          .keyword-text {
            max-width: 350px;
          }

          .keyword-type-text {
            max-width: 185px;

            h3 {
              color: #3f51b5;
            }
          }

          .inventory-edit {
            margin-left: 100px;
          }
        }

        .keyword-row:hover {
          background-color: rgba(0, 0, 0, 0.075);
        }

        .add-button {
          margin-top: 25px;
          margin-bottom: 40px;
        }
      }
    }

    #inventory {
      .submit {
        margin-top: 12px;
        margin-right: 40px;
      }

      #dynamic, #seim, #scrapers {
        .list-item:nth-child(1) {
          max-width: 100px;
        }

        .list-item.column-header:nth-child(2) {
          h3 {
            text-align: left;
            padding-left: 30px;
          }
        }

        .list-item.column-header:nth-child(3) {
          h3 {
            text-align: left;
            padding-left: 25px;
          }
        }

        .list-item.column-header:nth-child(4) {
          padding-left: 34px;

          h3 {
            text-align: left;
          }
        }

        #first-row {
          padding-left: 0;
        }
      }

      .divider {
        margin: 35px 0;
      }

      .scraper-select, .script-select {
        height: 48px;
      }

      .scraper-select {
        height:100%;

        div[class*='-control'] {
          height: 100%;
          width: 250px;
        }

      }

    .submit-unique {
        margin-left: 25px;
        margin-top: 0;
      }


      .search-box {
        margin-left: 100px;
      }


      #scripts {
        .script-row {
          width: 100%;
          margin: 25px 0;

          .active-icon {
            color: #28a745;
          }

          .card {
            width: 100%;
            padding: 10px 0;

            .card-content {
              padding-bottom: 0;
            }

            .header {
              margin-bottom: 14px;

              .spanish {
                margin-bottom: 0;
                margin-left: 20px;
                color: #3f51b5;
              }
            }

            .active-icon {
              margin-left: 15px;
            }

            .script-card-row {
              margin-top: 10px;

              .card-attr {
                margin: 0 15px;

                h6 {
                  margin-bottom: 0;
                  padding-top: 2px;
                  margin-right: 5px;
                }
              }

              .card-attr:first-of-type {
                margin-left: 0;
              }
            }

            .ads-panel {
              margin: 10px -16px 0;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
              box-shadow: none;

              .summary {
                padding: 0 16px;
                display: inline-flex;

                .icon {
                  left: 45px;
                  top: 43%;
                }
              }

              .panel-content {
                margin: 0;
              }

              .panel-details {
                padding-left: 49px;
                padding-top: 0;

                .ad {
                  margin-bottom: 15px;

                  .card-attr {
                    margin-left: 0;
                  }

                  .cpo, .over, .under {
                    margin-left: 10px;
                    padding-top: 3px;
                  }

                  .over {
                    color: #721c24;
                  }

                  .under {
                    color: #3f51b5;
                  }

                  .cpo {
                    color: #dc3545;
                  }
                }
              }
            }

            .ads-panel:before {
              background-color: #ffffff;
            }

            .card-action {
              justify-content: flex-end;
            }
          }

          .expanded {
            margin: 25px 0;
          }
        }
      }

      #scrapers {
        .list-item.column-header:nth-child(2) {
          h3 {
            text-align: left;
          }
        }
      }

      #logs {
        margin: 40px 0;

        .card {
          padding: 25px;

          .adGroupHeading {
            color: rgba(63, 81, 181, 0.75);
          }

          .divider {
            margin: 10px 0;
          }
        }
      }
    }

    #diagnostics {

      // MODEL YEAR

      #model-year {
        .heading {
          margin-bottom: 25px;

          h2.title {
            margin-right: 50px;
          }
        }

        .run-model-year {
          background-color: #d4edda;
          color: black;
          margin-bottom: 25px;
        }

        .paper {
          padding: 35px 50px;
          margin: 50px 0;

          .out, .in {
            margin-top: 25px;

            .ad-container {
              margin: 25px 0;

              h6 {
                margin-bottom: 0;
                min-width: 130px;
                text-align: right;
              }

              .text {
                margin-left: 25px;
                position: relative;
                bottom: 3px;
              }
            }
          }
        }
      }

      // IMAGES

      #images {

        .heading {
          margin-bottom: 25px;
          h2.title {
            margin-right: 50px;
          }
        }

        .run-images-diagnostic {
          background-color: #d4edda;
          color: black;
        }

        .image-headline {
          margin-top: 35px;
        }
      }

      #speed-test {
        .submit {
          margin-left: 0;
        }
      }
    }


    //GTM

    #gtm {
      .header {
        margin-bottom: 15px;
      }

      #editor {
        margin-left: 50px;
        width: 100%;
      }

      .tag-nav {
        position: relative;
        bottom: 15px;
      }

      #select {
        min-width: 215px;
      }

      .update-gtm-client {
        margin-top: 20px;
        float: right;
        background-color: #d4edda;
      }
    }

    #tests {
      .tests-header {
        margin-bottom: 25px;
      }
    }
  }

  .content.no-sidebar {
    margin-left: 0;
  }
}

.phone-leads-box {
  text-align: center;
  border-radius: 6px;
  // font-size: 10px;
  border: 1px solid #f8d7da;
  background: rgb(245,245,245);
  color: black;
  margin:10px;
  transition: background 200ms ease;
  transition-property: background, border-color, opacity;
  &.exempt {
    opacity: 0.7;
  }
  &:hover {
    opacity:1;
    background: rgb(244,244,244);
    border-color: var(--red)
  }
}

.phone-leads-header {
  padding: 10px;
  // font-size: 16px;
  line-height:1;
  margin-bottom:0;
  span {
    line-height:1.4;
  }
}

.phone-leads-data {
  // font-size:14px;
  padding-bottom:10px;
  > div {
    padding: 0 10%;
  }
  > div:nth-child(odd) {
    background: rgb(248, 248, 248);
  }
}

.phone-leads-numbers {
  // font-size:10px;
  line-height:2;
}

.phone-leads-reason {
  text-transform: capitalize;
}

.phone-leads-select-col {
  // display:grid;
  // grid-template: 2fr / 1fr;
  margin: 30px 0;
}

.phone-leads-run-text {
  display:flex;
  align-items:center;
}

.phone-leads-run-select {
  margin: 10px 0;
}

.run-phone-leads-button {
  background-color: #d4edda;
  color:black;
}

.phone-leads-button-clear {
  margin: 0 10px;
  background-color: #f8d7da;
}

.run-phone-leads-search-button {
  font-size:10px;
  margin-left: 5px;
  background-color: #d4edda;
  color: black;
}

.phone-leads-success-link {
  display:block;
}

.phone-leads-search-results {
  margin: 20px 0;
  margin-bottom: 50px;
  transition: max-height 200ms ease;
  max-height: 18000px;
}

.phone-leads-search-area {
  display: grid;
  grid-template: 1fr/5fr 1fr;
  align-items: center;
  input {
    width: 100%;
  }
}

.phone-leads-showing {
  font-size:10px;
  color: darkgray;
  font-style: italic;
}

.phone-leads-results-images,
.phone-leads-results-links,
.phone-leads-results-errors {
  margin: 20px 0;
}


.phone-leads-results-errors-grid,
.phone-leads-results-images-grid,
.phone-leads-results-links-grid {
  display: grid;
  grid-template: 1fr / 1fr;
  grid-gap: 15px;
  .phone-leads-box {
    margin: 0;
  }
}

.phone-leads-results-links-grid {
  display:inline-grid;
  grid-template: 1fr / repeat(5,1fr);
}

.phone-leads-results-images {
  img {
    max-width:100%;
  }
}

.phone-leads-results-links-items {
  display: inline-flex;
  flex-wrap: wrap;
  grid-gap: 10px;
}

.phone-leads-results-links small {
  font-size:16px;
  background: #eee;
  border-radius: 5px;
  padding:10px;
  line-height:1.3;
  border:1px solid var(--success);
  transition: border-color 200ms ease;
  &.error {
    border: 1px solid #f8d7da;
    &:hover {
      border-color: var(--danger);
    }
  }
}

.results-images-div-image {
  position:relative;
  border:1px solid var(--success);
  transition: border-color 200ms ease;
  &.error {
    border: 1px solid #f8d7da;
    &:hover {
      border-color: var(--danger);
    }
  }
}

.results-images-div-image-over {
  font-size:12px;
  line-height:1.2;
  color:black;
  position:absolute;
  left:0;
  bottom:0;
  z-index:1;
  padding:5px;
  background: #eee;
  border-top-right-radius: 8px;
}

@media only screen and ( min-width: 1024px ) {

  .phone-leads-results-images-grid {
      grid-template: 1fr / repeat(2,1fr);
  }

  .phone-leads-results-errors-grid {
    grid-template: 1fr / repeat(3,1fr);
  }

  .phone-leads-search-results {
    max-height: 500px;
    overflow: auto;
  }

  .phone-leads-row-select {
    display:block;
  }

  .phone-leads-row {
    display: grid;
    grid-template: 1fr / repeat(2,1fr);
    grid-gap: 10px;
  }

  // .phone-leads-select-col {
  //   grid-template: 1fr / 1fr 3fr;
  // }

}