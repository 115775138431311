.campaign-performance-device-breakdown {

    margin: 20px 10px 10px;
    min-height: 300px;
    width: calc( 100% - 20px );

    .eq {
        color: mdgv( $colors, 'hex', 'secondary' );
    }

    .up {
        color: green;
        &.cpv {
            color: darkred;
        }
    }

    .down {
        color: darkred;
        &.cpv {
            color: green;
        }
    }

    tbody tr {
        &:nth-child(even) {
            background: rgba(100,100,100,0.05);
        }
    }

    .larger {

        display: flex;
        flex-direction: column;
        align-items: center;

        .icon {
            font-size:24px;
        }
        span {
            font-size:10px;
        }

    }

    &:not(.loading) {
        background: white;
    }

}