.loading-wrapper {

  height: 100vh;
  background: #0d2832;

  .loading-icon {
    position: relative;
    width: 45px;
    height: 8px;

    @keyframes animateDot1 {
      0% {
        transform: rotate(0deg) translateX(-12px);
      }
      25% {
        transform: rotate(180deg) translateX(-12px);
      }
      75% {
        transform: rotate(180deg) translateX(-12px);
      }
      100% {
        transform: rotate(360deg) translateX(-12px);
      }
    }

    @keyframes animateDot2 {
      0% {
        transform: rotate(-0deg) translateX(-12px);
      }
      25% {
        transform: rotate(-180deg) translateX(-12px);
      }
      75% {
        transform: rotate(-180deg) translateX(-12px);
      }
      100% {
        transform: rotate(-360deg) translateX(-12px);
      }
    }

    @keyframes animateDot3 {
      0% {
        transform: rotate(0deg) translateX(12px);
      }
      25% {
        transform: rotate(180deg) translateX(12px);
      }
      75% {
        transform: rotate(180deg) translateX(12px);
      }
      100% {
        transform: rotate(360deg) translateX(12px);
      }
    }

    @keyframes animateDot4 {
      0% {
        transform: rotate(-0deg) translateX(12px);
      }
      25% {
        transform: rotate(-180deg) translateX(12px);
      }
      75% {
        transform: rotate(-180deg) translateX(12px);
      }
      100% {
        transform: rotate(-360deg) translateX(12px);
      }
    }

    .dot {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: darkslategrey;
      position: absolute;
    }

    .dot-1 {
      animation: animateDot1 1.5s linear infinite;
      left: 12px;
      background: rgba(18, 73, 91, 0.8);
    }

    .dot-2 {
      animation: animateDot2 1.5s linear infinite;
      animation-delay: 0.5s;
      left: 24px;
    }

    .dot-3 {
      animation: animateDot3 1.5s linear infinite;
      left: 12px;
    }

    .dot-4 {
      animation: animateDot4 1.5s linear infinite;
      animation-delay: 0.5s;
      left: 24px;
    }
  }
}