#dash-sidebar {

  position:fixed;
  min-width: 80px;
  background-image: linear-gradient(to top,#343d47,#404953);
  height: calc( 100% - 80px );
  overflow-y: auto;
  overflow-x: hidden;

  a {
    color: white;
    &:hover, &:active, &:visited {
      color: darken( white, 10% );
      text-decoration: none;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;


    li {
      cursor: pointer;
      height: 110px;

      .icon {
        font-size: 22.7px;
        margin-bottom: 10px;
      }

      .nav-text {
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        width: 75%;
      }
    }

    li.active {
      background-color: #4B5663;
    }

    li:hover {
      background-color: #414C58;
    }

    li.active:hover {
      background-color: #4B5663;
    }
  }

  //ul:nth-of-type(2) {
  //  position: fixed;
  //  bottom: 0;
  //  left: 0;
  //  transform: translateX(50%);
  //  li {
  //    padding-top: 10px;
  //    height: 100px;
  //  }
  //}

  @media only screen and (max-width:1020px) {
    ul li {
      height: 80px;
    }
  }

}