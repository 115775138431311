#conversion {

    display: flex;
    justify-content: stretch;
    align-content: space-evenly;

    .conversion-content {

        height: 380px;
        width: 100%;
        background-color: #FFFFFF;
        margin: 10px;
        padding: 20px;
        padding-top: 10px;
        padding-right: 40px;

        > .d-flex {
            justify-content: space-evenly;
        }

        #conversion-header {
            color: rgba(5,32,38,0.9);
            text-align: center;
            font-size: 26px;
        }

        .conversion-chart-area {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .conversion-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .conversion-sub-header {
            color: rgba(5, 32, 38, 0.9);
            font-weight: 900;
            font-size: 14px;
            text-align: center;
            margin: 14px 0;
        }

        .conversion-legend {
            display: flex;
            justify-content: space-evenly;
            width: 90%;
            flex-wrap: wrap;
            font-size: 10px;
            margin-bottom: 10px;
            min-width: 200px;

            > div {
                display:flex;
                align-items: center;
                span {
                    line-height:24px;
                }
            }

            .icon-phone-calls,
            .icon-document-page,
            svg {
                font-size: 16px;
                margin-right: 2px;
            }

        }

        #conversion-graph-container {

            display: flex;
            align-content: center;
            justify-content: center;
            position: relative;
            width: 100%;

            #conversion-pie-quadrant {

                position: absolute;
                top: 0;
                left: 6%;
                width: 88%;
                height: 100%;

                .quadrant-container {
                    display: grid;
                    grid-template: repeat(2,1fr) / repeat(2,1fr);
                    flex-wrap: wrap;
                    height: 100%;
                }

                .quadrant {
                    display: flex;

                    &.bottom-right {
                        align-items: flex-end;
                        justify-content: flex-end;
                        padding-right: 14px;
                    }

                    &.top-left {
                        align-items: flex-start;
                        justify-content: flex-start;
                        padding-left: 9px;
                    }

                    .conversion-metric {
                        color: rgb(255,255,255);
                        font-size: 30px;
                    }

                    .conversion-metric-icon {
                        font-weight: 500;
                        font-size: 35px;
                        color: rgb(255, 255, 255);
                    }

                }

                &.decimal {
                    .conversion-metric {
                        font-size: 20px;
                    }
                    .conversion-metric-icon {
                        font-size: 24px;
                    }
                }

            }

        }

        .conversion-metrics-container-wrapper {

            text-align: center;

            .conversion-date-range {
                margin: 14px 0;
                color: #3e3e3e;
                font-size: 14px;
                font-weight: 400;
            }

            .conversion-metrics-break {
                border-top: 2px solid rgb(238, 238, 238);
                margin: 6px 0 4px;
            }

            .conversion-metrics-container {

                display:flex;
                justify-content: space-around;
                flex-flow: wrap;

                &.current {
                    margin-top: 5px;
                    padding-bottom: 13px;
                    border-bottom: 1px solid gray;
                }

            }

            .conversion-metrics {
                color: #32375f;
                font-weight: 700;
                font-size: 30px;
            }

            .conversion-metric {
                display:flex;
                align-items:center;
                line-height: 43px;
            }

            .icon-phone-calls,
            .icon-document-page,
            svg {
                font-size: 16px;
                color: rgb(50, 55, 95);
                margin-right: 2px;
            }

            &.decimal {


                .conversion-metrics {
                    font-size: 20px;
                }

                .icon-phone-calls,
                .icon-document-page,
                svg {
                    font-size: 10px;
                }

            }

        }

        @media only screen and (max-width: 588px) {

            height: auto;
            min-height: 600px;

            .conversion-grid {
                grid-template-columns: 1fr;
            }

        }

    }

}

#conversion.loading {

    .conversion-content {
        background-color: #e9f3fb;
    }

}
