.campaign-performance {

    margin: 20px 10px 10px;
    background: #FFFFFF;
    width: calc( 100% - 20px );

    .eq {
        color: mdgv( $colors, 'hex', 'secondary' );
    }

    .up {
        color: green;
        &.cpc {
            color: darkred;
        }
    }

    .down {
        color: darkred;
        &.cpc {
            color: green;
        }
    }

    tbody tr {
        &:nth-child(even) {
            background: rgba(100,100,100,0.05);
        }
    }

    .total-row {
        //background-color: mdgv( $colors, 'hex', 'secondary' );
        background-color: rgb(35, 115, 176);
        td {
            color: white;
        }
        .up {
            color: darkgreen;
            &.cpc {
                color: darkred;
            }
        }
        .down{
            color: darkred;
            &.cpc {
                color: darkgreen;
            }
        }
        .eq {
            color: white;
        }

        td:nth-child(1) {
            font-weight: 600;
        }
    }

}

.campaign-performance.loading {

    background-color: #e9f3fb;
    min-height: 416px;

    .loading-wrapper {
        min-height: 416px;
    }

}